import React from "react";
import { Modal } from "reactstrap";
import PayPalSubscription from "./payPalSubscription";
import PayPalPayment from "./payPalPayment";

const PaymentModal = ({
  modal,
  onToggle,
  onCredit,
  propertyId,
  onUpdate,
  usuario,
  product,
  body,
  onPayment,
}) => {
  const handleCredit = () => {
    if (propertyId) {
      onCredit(propertyId);
    } else {
      onCredit();
    }
  };

  const handleToggle = () => {
    onToggle();
  };

  const handleClean = () => {
    onPayment();
  };

  const handleUpdate = () => {
    onUpdate();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className=""
        style={{ maxWidth: "400px" }}
      >
        <div className="modal-body p-1">
          <div className="row p-1">
            <div className="col text-center">
              <h4 style={{ margin: "5px 0" }}>
                <b>Selecciona tu forma de pago</b>
              </h4>
            </div>
          </div>
          <div className="row justify-content-center my-1">
            <div className="col text-center mb-2">
              <button
                className="btn btn-round btn-credit"
                onClick={handleCredit}
              >
                <i className="fa fa-credit-card fa-lg" aria-hidden="true" />
                &nbsp;&nbsp;Tarjeta de Crédito
              </button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div style={{ width: "350px", height: "60px" }}>
              {product ? (
                <PayPalPayment
                  onModal={handleToggle}
                  onPayment={handleClean}
                  product={product}
                  body={body}
                />
              ) : (
                <PayPalSubscription
                  propertyId={propertyId}
                  onModal={handleToggle}
                  onUpdate={handleUpdate}
                  usuario={usuario}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentModal;

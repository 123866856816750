import http from "./httpService";

export function sendEmail(info) {
  return http.post("/email", info);
}

export function responseEmail(body) {
  const id = body.propiedadId;
  delete body.propiedadId;
  return http.post("/email/" + id, body);
}

import React from "react";
import { Radar } from "react-chartjs-2";

const RadarChart = (props) => {
  const { data, color, labels, title } = props;
  return (
    <Radar
      data={{
        datasets: [
          {
            data: data,
            backgroundColor: "rgba(" + color + ",0.3)",
            borderColor: "rgba(" + color + ",1)",
          },
        ],
        labels: labels,
      }}
      width={100}
      height={200}
      options={{
        maintainAspectRatio: false,
        title: {
          display: true,
          text: title,
        },
        legend: {
          display: false,
        },
        plugins: { datalabels: { display: false } },
        scale: {
          pointLabels: {
            fontSize: 11,
          },
        },
      }}
    />
  );
};

export default RadarChart;

import React, { Component } from "react";
import wNumb from "wnumb";
import Swal from "sweetalert2";
import _ from "lodash";
import IndexNavbar from "./indexNavbar";
import auth from "../services/authService";
import DoughnutChart from "./common/doughnutChart";
import RadarChart from "./common/radarChart";
import LinearChart from "./common/linearChart";
import PremiumModal from "./common/premiumModal";
import {
  PremiumMap,
  drawPremium,
  colorLayer,
  clearLayer,
  mapUpdate,
  markerPremium,
  markerSucursal,
  colorMarker,
  getAddress,
  deleteSuc,
} from "./common/premiumMap";
import { getPoints } from "../services/premiumService";
import { newSucursal, getSucursales } from "../services/sucursalService";

class Premium extends Component {
  state = {
    loadinfo: false,
    layer: "clear",
    colors: [],
    data: [],
    marker: 0,
    modal: false,
    newPlace: false,
    sucursal: { titulo: "", direccion: "", mapeo: false, lat: 0, lng: 0 },
  };

  handleModal = () => {
    const modal = !this.state.modal;
    this.setState({ modal });
  };

  handleNewPlace = () => {
    const newPlace = !this.state.newPlace;
    this.setState({ newPlace });
  };

  handleText = ({ currentTarget: input }) => {
    const sucursal = { ...this.state.sucursal };
    sucursal[input.name] = input.value;
    this.setState({ sucursal });
  };

  mapAddress = async () => {
    try {
      const sucursal = { ...this.state.sucursal };
      if (sucursal.direccion.length >= 10) {
        const { marker, mapas } = await getAddress(sucursal.direccion);
        sucursal["lat"] = marker.getPosition().lat();
        sucursal["lng"] = marker.getPosition().lng();
        sucursal["mapeo"] = true;
        this.setState({ sucursal });
        mapas.event.addListener(marker, "dragend", (e) => {
          const sucursal = { ...this.state.sucursal };
          sucursal["lat"] = marker.getPosition().lat();
          sucursal["lng"] = marker.getPosition().lng();
          this.setState({ sucursal });
        });
        Swal.fire(
          "¡La encontramos!",
          "Puedes arrastrar el pin para la ubicación exacta",
          "success"
        );
      }
    } catch (e) {
      Swal.fire(
        "¡Ups!",
        "No encontramos la dirección de tu sucursal, intenta nuevamente o esribenos a info@capitolcity2.com",
        "error"
      );
    }
  };

  clearSucursal = () => {
    const sucursal = { ...this.state.sucursal };
    sucursal["mapeo"] = false;
    this.setState({ sucursal });
    deleteSuc();
  };

  addSucursal = async () => {
    try {
      const sucursal = { ...this.state.sucursal };
      if (sucursal.titulo.length > 0) {
        const body = { ...sucursal };
        body["titulo"] = body["titulo"].toUpperCase();
        delete body.direccion;
        delete body.mapeo;
        const { id: userId } = auth.getCurrentUser();
        body["usuario_id"] = userId;
        //Llamamos al servicio
        const res = await newSucursal(body);
        if (res.status === 200) {
          Swal.fire(
            "¡Listo!",
            "Tu sucursal se agrego con éxito. Refresca para solicitar su análisis.",
            "success"
          );
          const coords = { lat: sucursal.lat, lng: sucursal.lng };
          markerSucursal(coords, sucursal.titulo, 0);
          sucursal["titulo"] = "";
          this.setState({ sucursal });
          //Limpiar Pin Amarrillo
          this.clearSucursal();
        } else {
          Swal.fire(
            "¡Ups!",
            "Ocurrio un error, intenta nuevamente o envianos un correo a info@capitolcity2.com",
            "error"
          );
        }
      } else {
        Swal.fire("¡Espera!", "Escribe el título para tu sucursal", "error");
      }
    } catch {}
  };

  loadInformation = async () => {
    try {
      const { id: userId } = auth.getCurrentUser();
      const { data } = await getPoints(userId);
      if (data.length >= 1) {
        this.addMarkerInfo(data);
      } else {
        Swal.fire(
          "Todavía no solicitas un punto para análisis",
          "Solicítalo en: Nuevo Análisis",
          "info"
        );
      }
      //Mapear las sucursales que no tienen solicitud de análisis
      const { data: sucursales } = await getSucursales(userId);
      if (sucursales.length >= 1) {
        for (let i = 0; i < sucursales.length; i++) {
          const coords = { lat: sucursales[i].lat, lng: sucursales[i].lng };
          markerSucursal(coords, sucursales[i].titulo, 0);
        }
      }
    } catch (e) {
      Swal.fire(
        "Error en carga de información",
        "Envianos un correo a info@capitolcity2.com con tu usuario para revisar el caso",
        "error"
      );
    }
  };

  addMarkerInfo = async (info) => {
    let data = [];
    const route = process.env.REACT_APP_GH_SHP;
    for (let i = 0; i < info.length; i++) {
      /* Agregar el marcador */
      const coords = { lat: info[i].lat, lng: info[i].lng };
      const { marker, mapas } = info[i].sucursal
        ? markerSucursal(coords, info[i].titulo, 1)
        : markerPremium(coords, info[i].status, 0, 0);
      const icono =
        "http://maps.google.com/mapfiles/ms/micons/" +
        (info[i].sucursal ? "red-pushpin.png" : "red-dot.png");
      marker.metadata = { id: i };
      mapas.event.addListener(marker, "click", (e) => {
        this.markerUpdate(marker.metadata.id);
        marker.setIcon(icono);
        colorMarker(marker.metadata.id);
      });
      if (i === 0) {
        marker.setIcon(icono);
      }
      /* Agregar el marcador */
      /* Variables para totales */
      let pobTotal = 0;
      let vivTotal = 0;
      let pobLocal = 0;
      let pobFor = 0;
      let pobTotHab = 0;
      let vivHab = 0;
      let ocuHab = 0;
      let ocuAvg = 0;
      let educacion = 0;
      let recreativo = 0;
      let laboral = 0;
      let nb = 0;
      let salud = 0;
      let nse = 0;
      let nseText = "NA";
      let countnse = 0;
      let km2 = 1;
      let totalTraf = 0;
      let trafico = info[i].trafico
        ? info[i].trafico.split(",").map(Number)
        : [];
      /* Variables para totales */
      /* Validar si esta completo */
      if (info[i].status === 2) {
        await fetch(route + info[i].archivo)
          .then((response) => response.json())
          .then((jsonData) => {
            //console.log(jsonData);
            /* Extraer datos */
            drawPremium(jsonData);
            const len = jsonData.features.length;
            let feat = [];
            for (let x = 0; x < len; x++) {
              feat = jsonData.features[x];
              pobTotal += feat.properties.Demo_POBTOT;
              vivTotal += feat.properties.Demo_TVIVPAR;
              pobLocal += feat.properties.Demo_PNACENT;
              pobFor += feat.properties.Demo_PNACOE;
              vivHab += feat.properties.Demo_VIVPAR_HAB;
              ocuHab += feat.properties.Demo_OCUPVIVPAR;
              educacion += feat.properties.Radar_Educacion;
              recreativo += feat.properties.Radar_Recreativo;
              laboral += feat.properties.Radar_Laboral;
              nb += feat.properties.Radar_NB;
              salud += feat.properties.Radar_Salud;
              km2 += feat.properties.Km2;
              nse += feat.properties.Data_NSE;
              countnse = feat.properties.Data_NSE > 0 ? countnse + 1 : countnse;
            }
            pobTotHab = pobLocal + pobFor;
            pobLocal = pobLocal / pobTotHab;
            pobFor = pobFor / pobTotHab;
            ocuAvg = parseFloat((ocuHab / vivHab).toFixed(2));

            for (let z = 0; z < trafico.length; z++) {
              totalTraf += trafico[z] * 14;
            }

            nse = nse / countnse;
            /* Extraer datos */
          })
          .catch((error) => {
            console.error(error);
          });
      }
      /* Validar si esta completo */
      km2 = km2 === 1 ? 1 : parseFloat(km2.toFixed(2));
      nseText =
        nse > 0
          ? nse <= 2
            ? "Alto"
            : nse > 2 && nse <= 3
            ? "Medio-Alto"
            : nse > 3 && nse <= 4
            ? "Medio-Bajo"
            : "Bajo"
          : "NA";
      /* Insertar en el arreglo data */
      data.push({
        pobTotal,
        vivTotal,
        pobLocal,
        pobFor,
        ocuAvg,
        kvar: [
          parseFloat(laboral / km2 / 2).toFixed(2),
          parseFloat(salud / km2 / 8).toFixed(2),
          parseFloat(educacion / km2 / 8).toFixed(2),
          parseFloat(recreativo / km2 / 4).toFixed(2),
          parseFloat(nb / km2 / 4).toFixed(2),
        ],
        trafico,
        totalTraf,
        km2,
        nseText,
        calle: info[i].calle,
        titulo: info[i].titulo,
      });
      /* Insertar en el arreglo data */
    } // Termina el for
    this.setState({ loadinfo: true, data });
  };

  markerUpdate = (marker) => {
    this.setState({ marker: marker });
  };

  handleMap = ({ currentTarget: input }) => {
    const map = input.value;
    mapUpdate(map);
  };

  handleHelp = () => {
    Swal.fire({
      title: "Detalles de métricas",
      html:
        "<ul style='text-align:justify'><li><p><b><b>Alcance:</b> Área cubierta manejando 5 minutos con tráfico</b></p></li><li><p><b><b>Tráfico:</b> Cantidad de vehículos que pasan regularmente enfrente del punto por semana</b></p></li><li><p><b><b>Composición del Área:</b> Índice que muestra la concentración de factores en el área:<ul style='text-align: left;'><li><p><b>Laboral:</b> Empresas de Manufactura, Corporativos, Oficinas</p></li><li><p><b>Salud:</b> Hospitales, Farmacias, Consultorios, Dentisas etc.</p></li><li><p><b>Educación:</b> Primarias, Secundarias, Preparatorias etc.</p></li><li><p><b>Recreativo:</b> Restaurantes, Plazas Comerciales, Tiendas Departamentales</p></li><li><p><b>Abarrotes:</b> Supermercados, OXXO, Seven</p></li></ul></b></p></li></ul>",
      icon: "info",
    });
  };

  handleLayer = ({ currentTarget: input }) => {
    const layer = input.value;
    let arrayColors = [];
    let limits = [];
    let field = "";
    switch (layer) {
      case "nse":
        arrayColors = ["#2c7bb6", "#abd9e9", "#ffffbf", "#fdae61", "#d7191c"];
        limits = [2, 3, 4, 5];
        field = "Data_NSE";
        break;
      case "dpob":
        arrayColors = ["#fee5d9", "#fcae91", "#fb6a4a", "#cb181d"];
        limits = [4240, 10930, 21410];
        field = "Densidad_Pob";
        break;
      case "dviv":
        arrayColors = ["#feebe2", "#fbb4b9", "#f768a1", "#ae017e"];
        limits = [1150, 2900, 5500];
        field = "Densidad_Viv";
        break;
      case "young":
        arrayColors = ["#ffffcc", "#a1dab4", "#41b6c4", "#2c7fb8", "#253494"];
        limits = [0.05, 0.11, 0.17, 0.33];
        field = "Jovenes";
        break;
      case "old":
        arrayColors = ["#ffffd4", "#fed98e", "#fe9929", "#d95f0e", "#993404"];
        limits = [0.04, 0.09, 0.17, 0.42];
        field = "Mayores";
        break;
      case "salud":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [3, 6, 9, 12];
        field = "Radar_Salud";
        break;
      case "recreativo":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [2, 5, 7, 9];
        field = "Radar_Recreativo";
        break;
      case "laboral":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [3, 6, 9, 12];
        field = "Radar_Laboral";
        break;
      case "educacion":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [3, 6, 9, 12];
        field = "Radar_Educacion";
        break;
      case "nb":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [2, 5, 7, 9];
        field = "Radar_NB";
        break;
      default:
        clearLayer();
    }
    if (layer !== "clear") {
      colorLayer(arrayColors, limits, field);
    }
    let colors = arrayColors;
    colors = layer === "nse" ? colors : colors.slice().reverse();
    this.setState({ colors, layer });
  };

  render() {
    const {
      colors,
      loadinfo,
      data,
      marker,
      modal,
      newPlace,
      sucursal,
    } = this.state;
    const donaPob = data.length
      ? JSON.parse(JSON.stringify([data[marker].pobLocal, data[marker].pobFor]))
      : [];
    const radarSect = data.length
      ? JSON.parse(JSON.stringify(data[marker].kvar))
      : [];
    const dataset = data.length
      ? [
          {
            data: JSON.parse(JSON.stringify(data[marker].trafico)),
            borderColor: "rgba(255, 86, 0, 1)",
            pointBackgroundColor: "rgba(255, 86, 0, 1)",
            fill: false,
          },
        ]
      : null;
    const commaFormat = wNumb({
      thousand: ",",
    });
    let minLine = data.length ? _.min(data[marker].trafico) : 0;
    let maxLine = data.length ? _.max(data[marker].trafico) : 0;
    let stepSize = data.length
      ? parseInt(
          (_.max(data[marker].trafico) - _.min(data[marker].trafico)) / 3
        )
      : 1;
    stepSize = Math.ceil(stepSize / 10) * 10;
    minLine = Math.floor(minLine / 10) * 10 - stepSize;
    maxLine = Math.ceil(maxLine / 10) * 10 + stepSize;
    minLine = minLine < 0 ? 0 : minLine;
    return (
      <React.Fragment>
        <PremiumModal modal={modal} onToggle={this.handleModal} />
        <IndexNavbar
          clase="sticky-top"
          user={this.props.user}
          page={this.props.match.path}
        />
        <div className="row mt-2">
          <div className="col-md-6">
            <div className="col text-center p-2">
              <span style={{ fontSize: "16px" }}>
                <b>Resultados de Análisis</b>
              </span>
              <i
                className="fa fa-info-circle ml-1 text-danger align-self-center"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={this.handleHelp}
              ></i>
            </div>
            <div className="col text-center">
              <div className="col text-center mb-1">
                <span className="text-sblue" style={{ fontSize: "16px" }}>
                  <b>{data.length ? data[marker].titulo : null}</b>
                </span>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <b>Mapa</b>
                    </label>
                    <select className="form-control" onChange={this.handleMap}>
                      <option value="hibrido">Hibrido</option>
                      <option value="calles">Calles</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <b>Visualización</b>
                    </label>
                    <select
                      className="form-control"
                      onChange={this.handleLayer}
                      disabled={!loadinfo}
                    >
                      <option value="clear">No Mostar Capa</option>
                      <option value="nse">Nivel Socioeconómico</option>
                      <option value="dpob">Densidad Poblacional</option>
                      <option value="dviv">Densidad Viviendas</option>
                      <option value="salud">Sector Salud</option>
                      <option value="laboral">Sector Laboral</option>
                      <option value="recreativo">Sector Recreativo</option>
                      <option value="educacion">Sector Educación</option>
                      <option value="nb">Sector Supermercados</option>
                      <option value="young">{"Población < 25 Años"}</option>
                      <option value="old">{"Población > 60 Años"}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col pb-3">
                <div className="row">
                  <div className="col-md-4">
                    <span>
                      <b>
                        {"Población: " +
                          commaFormat.to(
                            data.length ? data[marker].pobTotal : 0
                          )}
                      </b>
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span>
                      <b>
                        {" "}
                        {"Viviendas: " +
                          commaFormat.to(
                            data.length ? data[marker].vivTotal : 0
                          )}
                      </b>
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span>
                      <b>
                        {"Alcance: " +
                          commaFormat.to(data.length ? data[marker].km2 : 0) +
                          " Km²"}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col">
                    <span>
                      <b>
                        Nivel Socioeconómico:{" "}
                        {data.length ? data[marker].nseText : "NA"}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <DoughnutChart
                      data={donaPob}
                      colors={[
                        "rgba(255, 86, 0, 1)",
                        "rgba(18, 165, 255, 1),rgba(18, 165, 255, 1)",
                      ]}
                      labels={["Locales", "Foraneos"]}
                      title={"Distribución Poblacional"}
                    />
                  </div>
                  <div className="col-md-6 justify-content-center">
                    <RadarChart
                      data={radarSect}
                      color={"18, 165, 255"}
                      labels={[
                        "Laboral",
                        "Salud",
                        "Educación",
                        "Recreativo",
                        "Abarrotes",
                      ]}
                      title={"Composición del Área"}
                    />
                  </div>
                </div>
              </div>
              <div className="col pb-2">
                <span className="pb-2">
                  <b>
                    Tráfico Semanal:{" "}
                    {commaFormat.to(data.length ? data[marker].totalTraf : 0)}
                  </b>
                </span>
                <div>
                  <LinearChart
                    dataset={dataset}
                    labels={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
                    title={"Vehículos/Hora"}
                    stepSize={stepSize}
                    min={minLine}
                    max={maxLine}
                    legend={false}
                  />
                </div>
                <span className="pb-2">
                  <b>Calle: {data.length ? data[marker].calle : ""}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {newPlace ? (
              <React.Fragment>
                <div className="row py-1">
                  <div className="col-md-2">
                    <span className="align-self-center">
                      <b>Dirección:</b>
                    </span>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ej. Santander 40, Las Torres 64930, Monterrey, Nuevo Léon"
                      style={{
                        height: "25px",
                      }}
                      value={sucursal.direccion}
                      name="direccion"
                      onChange={this.handleText}
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-md-2">
                    <span className="align-self-center">
                      <b>Título:</b>
                    </span>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ej. Sucursal #10"
                      style={{
                        height: "25px",
                      }}
                      value={sucursal.titulo}
                      name={"titulo"}
                      onChange={this.handleText}
                      maxLength="50"
                    />
                  </div>
                  <div className="col-md-4">
                    {!sucursal.mapeo ? (
                      <React.Fragment>
                        <span
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={this.mapAddress}
                        >
                          <b>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />
                            &nbsp;Mapear
                          </b>
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span
                          className="text-sblue pl-2"
                          style={{ cursor: "pointer" }}
                          onClick={this.clearSucursal}
                        >
                          <b>
                            <i className="fa fa-times" aria-hidden="true" />
                            &nbsp;Borrar
                          </b>
                        </span>
                        <span
                          className="text-danger pl-2"
                          style={{ cursor: "pointer" }}
                          onClick={this.addSucursal}
                        >
                          <b>
                            <i className="fa fa-floppy-o" aria-hidden="true" />
                            &nbsp;Agregar
                          </b>
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            <div className="row">
              <div className="col-md-4 ">
                {loadinfo ? (
                  <React.Fragment>
                    <span>
                      <b>
                        {" "}
                        <i
                          className="fa fa-thumb-tack"
                          aria-hidden="true"
                          style={{ color: "#E661AC" }}
                        />{" "}
                        Sucursal Activa&nbsp;&nbsp;
                      </b>
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      className={loadinfo ? "text-inactive" : "text-danger"}
                      onClick={loadinfo ? null : this.loadInformation}
                    >
                      <b>
                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                        Cargar Resultados&nbsp;&nbsp;
                      </b>
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className="col-md-4">
                <span
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                  onClick={this.handleModal}
                >
                  <b>
                    <i className="fa fa-plus" aria-hidden="true"></i>Nuevo
                    Análisis
                  </b>
                </span>
              </div>
              <div className="col-md-4">
                <span
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                  onClick={this.handleNewPlace}
                >
                  <b>
                    <i className="fa fa-plus" aria-hidden="true"></i>Nueva
                    Sucursal
                  </b>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ">
                <span>
                  <b>
                    {" "}
                    <i
                      className="fa fa-map-marker"
                      aria-hidden="true"
                      style={{ color: "#6991FD" }}
                    />{" "}
                    Solicitud en Proceso&nbsp;&nbsp;
                  </b>
                </span>
              </div>
              <div className="col-md-4 ">
                <span>
                  <b>
                    <i
                      className="fa fa-map-marker"
                      aria-hidden="true"
                      style={{ color: "#00E64D" }}
                    />{" "}
                    Información Lista&nbsp;&nbsp;
                  </b>
                </span>
              </div>
              <div className="col-md-4">
                <span>
                  <b>
                    {" "}
                    {colors.length ? (
                      <React.Fragment>
                        {" "}
                        <span className="px-2">Alto</span>
                        <span>
                          {colors.map((color, i) => {
                            return (
                              <i
                                key={i}
                                className="fa fa-square fa-lg"
                                aria-hidden="true"
                                style={{ color: color }}
                              />
                            );
                          })}
                        </span>
                        <span className="p-2">Bajo</span>{" "}
                      </React.Fragment>
                    ) : null}
                  </b>
                </span>
              </div>
            </div>
            <div
              style={{
                height: "550px",
                paddingBottom: "5px",
              }}
            >
              <PremiumMap
                center={{ lat: 25.617847, lng: -100.287275 }}
                zoom={5}
                type={"hybrid"}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Premium;

import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import LzCarousel from "./lzCarousel";
import wNumb from "wnumb";

const LzCardMap = (props) => {
  const fotos = props.data.fotos.split(",");
  const moneyFormat = wNumb({
    mark: ".",
    thousand: ",",
    prefix: "$ ",
  });
  const commaFormat = wNumb({
    thousand: ",",
  });

  return (
    <Col>
      <div className="card" style={{ fontSize: "15px" }}>
        <LzCarousel className="card-img-top" fotos={fotos} />
        <div className="card-body">
          <Row className="pt-1 justify-content-center">
            <span>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              <b>{props.data.colonia}</b>
            </span>
          </Row>
          <Row className="justify-content-center">
            <span>
              <i className="fa fa-arrows-h" aria-hidden="true"></i>
              <b>{commaFormat.to(props.data.metros)} &nbsp;&nbsp;</b>
            </span>
            <span>
              <i className="fa fa-money" aria-hidden="true"></i>
              <b>{moneyFormat.to(props.data.precio)}</b>
            </span>
          </Row>
          <Row className="justify-content-center mt-1">
            <span className="ct-red">
              <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              <Link
                to={"/maps/" + props.data.id}
                target="_blank"
                style={{ color: "#f7765f" }}
              >
                <b>Ver Propiedad</b>
              </Link>
            </span>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default LzCardMap;

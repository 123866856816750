import React, { Component } from "react";
import { Image } from "cloudinary-react";
import { uploadImage } from "../services/uploadService";

class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
    };
  }

  state = {};

  onChangeHandler = (event) => {
    //console.log(event.target.files);
    this.setState({ selectedFile: event.target.files });
  };

  onClickHandler = async () => {
    const data = new FormData();
    for (var x = 0; x < this.state.selectedFile.length; x++) {
      console.log(this.state.selectedFile[x]);
      data.append("file", this.state.selectedFile[x]);
    }
    console.log(this.state);
    await uploadImage(data); //Mandar JSON
  };

  render() {
    return (
      <div className="Container">
        <div className="row">
          <div className="offset-md-3 col-md-6">
            <div className="form-group files">
              <label>Upload Your Files</label>
              <input
                type="file"
                className="form-control"
                name="file"
                multiple
                onChange={this.onChangeHandler}
              ></input>
            </div>
            <button
              type="button"
              className="btn btn-success"
              onClick={this.onClickHandler}
            >
              Upload
            </button>
          </div>
        </div>
        <div>
          <Image
            cloudName="big-duck-tech"
            publicId="Landzilla/e6seijuavq3dzcivgaxg"
            width="500"
          ></Image>
        </div>
      </div>
    );
  }
}
export default UploadImages;

import React from "react";
import Swal from "sweetalert2";
import { PayPalButton } from "react-paypal-button-v2";
import { updatePayPal } from "../../services/premiumService";
import { updatePayPalTrafico } from "../../services/traficoService";
import { payPalMail } from "../../services/checkOutService";

const PayPalPayment = ({ onModal, onPayment, product, body }) => {
  const options = {
    clientId: process.env.REACT_APP_PAYPAL,
    currency: "MXN",
    disableFunding: "card",
  };

  const style = {
    label: "pay",
    size: "medium",
    shape: "pill",
    color: "gold",
  };

  const createOrder = (data, actions) => {
    const val =
      product === 1
        ? process.env.REACT_APP_PAYPAL_PROD_AP
        : process.env.REACT_APP_PAYPAL_PROD_TRAF;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "MXN",
            value: val,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(async (response) => {
        let data = { ...body };
        data["payCode"] = response.id;
        data["payType"] = "PP";
        onModal();
        onPayment();
        let res = "";
        let template = "";
        let title = ";";
        data["status"] = 1;
        template = product === 1 ? "premium" : "trafico";
        title = "Análisis Solicitado";
        res =
          product === 1
            ? await updatePayPal(data)
            : await updatePayPalTrafico(data);
        if (res.status === 200) {
          const body = { template: template, title: title };
          await payPalMail(body);
          Swal.fire({
            title: "¡Listo!",
            text:
              "¡Tu solicitud fue recibida con exito!. Te enviamos un correo con los detalles.",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "¡Ups!",
            text:
              "Ocurrio un error, manda un correo a info@capitolcity2.com para ayudarte",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "¡Ups!",
          text:
            "Ocurrio un error, manda un correo a info@capitolcity2.com para ayudarte",
          icon: "error",
        });
      });
  };

  const onError = (error) => {
    console.log(error);
  };

  const onCancel = (data, actions) => {
    console.log("Proceso cancelado");
  };

  return (
    <PayPalButton
      options={options}
      style={style}
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onCancel={(data, actions) => onCancel(data, actions)}
      onError={(error) => onError(error)}
    />
  );
};

export default PayPalPayment;

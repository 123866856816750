import React from "react";
import { Col, Row } from "reactstrap";
import wNumb from "wnumb";
import Countdown from "react-countdown";
import LzCarousel from "./lzCarousel";

const LzCard = (props) => {
  const { isActive, isAvailable, isActiveRegistro } = props.data;
  const fotos = props.data.fotos.split(",");
  const moneyFormat = wNumb({
    mark: ".",
    thousand: ",",
    prefix: "$ ",
  });
  const commaFormat = wNumb({
    thousand: ",",
  });

  const handleClick = () => {
    props.onReview(props.data.id);
  };

  const handleActive = () => {
    props.onActive(props.data.id);
  };

  const handleDelete = () => {
    props.onDelete(props.data.id);
  };

  const handleAvailable = () => {
    props.onAvailable(props.data.id, props.data.tipo);
  };

  const handlePrivate = () => {
    props.onPrivate(props.data.id, props.data.isActive);
  };

  const renderer = ({ formatted: { days, hours, minutes, seconds } }) => {
    return (
      <span>
        {days} días {hours}:{minutes}:{seconds}
      </span>
    );
  };

  let expiration = 0;
  let fecha = 0;

  if (isActive) {
    fecha = new Date(isActiveRegistro);
    expiration = fecha.setDate(fecha.getDate() + 30);
  }

  const precioMetro = parseInt(props.data.precio / props.data.metros);
  const flag = props.flag;

  return (
    <Col md={flag ? "6" : "4"} style={{ fontSize: flag ? "13px" : "14px" }}>
      <div className="card">
        <LzCarousel className="card-img-top" fotos={fotos} />
        <div className="card-body">
          {!flag && (
            <React.Fragment>
              <Row
                style={{
                  borderBottom:
                    "3px solid " +
                    (isActive
                      ? "rgba(255, 102, 0,0.8)"
                      : !isAvailable
                      ? "rgba(132, 245, 34,0.8)"
                      : "rgba(255, 0, 153,0.8)"),
                }}
                className="full-color-bar"
              >
                <div className="col align-self-center color-bar">
                  <a href="#lz" onClick={handleClick}>
                    <i className="fa fa-cog fa-lg" aria-hidden="true" />
                  </a>
                  <a
                    className="align-self-center ml-2"
                    style={{ cursor: "pointer" }}
                    href="#del"
                    onClick={handleDelete}
                  >
                    <i className="fa fa-trash fa-lg" aria-hidden="true" />
                  </a>
                </div>
                <div className="col-md-6 text-center color-bar">
                  <span
                    className="color-bar-text"
                    style={{ fontWeight: "bolder", fontSize: "18px" }}
                  >
                    <b>
                      {isActive
                        ? "Pública"
                        : !isAvailable
                        ? props.data.tipo === "Venta"
                          ? "Vendida"
                          : "Rentada"
                        : "Privada"}
                    </b>
                  </span>
                </div>
                <div className="col align-self-center color-bar-pad">
                  <span>
                    <i className="fa fa-eye fa-lg" aria-hidden="true" />
                    <b> {props.data.vistas}</b>
                  </span>
                </div>
              </Row>
            </React.Fragment>
          )}
          <h5 className="card-title text-center pb-2">
            <b>
              {!flag && (
                <React.Fragment>
                  {props.data.titulo}&nbsp;({props.data.tipo})&nbsp;
                </React.Fragment>
              )}
            </b>
          </h5>
          {flag ? (
            <Row className="justify-content-center">
              <span>
                <b>{props.data.tipo}</b>
              </span>
            </Row>
          ) : null}

          <Row className="pt-1 justify-content-center">
            <span>
              <i className="fa fa-location-arrow" aria-hidden="true"></i>
              <b>{props.data.ciudad}</b>
            </span>
          </Row>
          <Row className="pt-1 justify-content-center">
            <span>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              <b>{props.data.colonia}</b>
            </span>
          </Row>
          {!flag && (
            <Row className="pt-1 justify-content-center">
              <span>
                <i className="fa fa-file-text" aria-hidden="true" />
                <b>
                  Agua
                  <i
                    className={props.data.agua ? "fa fa-check" : "fa fa-times"}
                    aria-hidden="true"
                    style={{ color: props.data.agua ? "#32a852" : "#f7765f" }}
                  />{" "}
                  Drenaje
                  <i
                    className={
                      props.data.drenaje ? "fa fa-check" : "fa fa-times"
                    }
                    aria-hidden="true"
                    style={{
                      color: props.data.drenaje ? "#32a852" : "#f7765f",
                    }}
                  />{" "}
                  Luz{" "}
                  <i
                    className={props.data.luz ? "fa fa-check" : "fa fa-times"}
                    aria-hidden="true"
                    style={{ color: props.data.luz ? "#32a852" : "#f7765f" }}
                  />{" "}
                </b>
              </span>
            </Row>
          )}
          <Row className="pt-1 justify-content-center">
            <span>
              <i className="fa fa-arrows-h" aria-hidden="true"></i>
              <b>{commaFormat.to(props.data.metros)}</b>
            </span>
          </Row>
          <Row className="justify-content-center">
            <span>
              <i className="fa fa-money" aria-hidden="true"></i>
              <b>
                {moneyFormat.to(props.data.precio)} (
                {moneyFormat.to(precioMetro)} m²)
              </b>
            </span>
          </Row>
          <Row className={"pt-3 justify-content-center"}>
            {flag && (
              <div className="col text-center">
                <a
                  href={"/maps/" + props.data.id}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "rgba(255,86,0,1)", fontWeight: "bolder" }}
                >
                  <b>Revisar Propiedad</b>
                </a>
              </div>
            )}
            {!flag && (
              <div className="col-md-6 align-self-center">
                {isActive && isAvailable ? (
                  <button
                    className={"col btn btn-round btn-gray btn-sm"}
                    onClick={handleAvailable}
                  >
                    <span style={{ color: "#000" }}>
                      {" "}
                      {props.data.tipo === "Venta" ? "Vendida" : "Rentada"}
                    </span>
                  </button>
                ) : !isAvailable ? (
                  <button
                    className="col btn btn-round btn-gray btn-sm"
                    onClick={handlePrivate}
                  >
                    <span style={{ color: "#000" }}> Privada </span>
                  </button>
                ) : (
                  <button
                    className="col btn btn-round btn-sm btn-danger"
                    onClick={handleActive}
                  >
                    Publicar
                  </button>
                )}
              </div>
            )}
            {!flag && isActive ? (
              <div className="col-md-6 align-self-center">
                <button
                  className="col btn btn-round btn-gray btn-sm"
                  onClick={handlePrivate}
                >
                  <span style={{ color: "#000" }}> Privada </span>
                </button>
              </div>
            ) : null}
          </Row>
          {!flag ? (
            isActive ? (
              <Row className="pt-3 justify-content-center">
                <span
                  style={{
                    fontSize: "18px",
                  }}
                  className="text-sred"
                >
                  <b>
                    <i className="fa fa-calendar pr-1" aria-hidden="true" />{" "}
                    Expira en:&nbsp;
                    <Countdown
                      date={expiration}
                      zeroPadTime={2}
                      zeroPadDays={2}
                      renderer={renderer}
                    />
                  </b>
                </span>
              </Row>
            ) : null
          ) : null}
          <Row
            className="justify-content-end"
            style={{ padding: "0", margin: "0" }}
          ></Row>
        </div>
      </div>
    </Col>
  );
};

export default LzCard;

import React from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { LoadingComponent } from "./loadingComponent";
import { Card, Col, Form, Button, CardBody, NavLink } from "reactstrap";
import LzForm from "./lzForm";
import { createUser, updateUser } from "../../services/userService";
import auth from "../../services/authService";

class RegisterForm extends LzForm {
  state = {
    data: {
      nombre: "",
      apellido: "",
      usuario: "",
      password: "",
      telefono: "",
    },
    errors: {},
    acceptPolicy: false,
  };
  schema = {
    nombre: Joi.string().required().label("Nombre"),
    apellido: Joi.string().required().label("Apellido"),
    usuario: Joi.string().email().required().label("Email"),
    password: Joi.string().required().label("Password"),
    telefono: Joi.optional().label("Telefono"),
    propsact: Joi.optional().label("PActivas"),
    propsreg: Joi.optional().label("PRegistradas"),
  };

  componentDidMount() {
    const { updateData, data } = this.props;
    if (updateData) {
      this.setState({ data });
    }
  }

  handleCheck = () => {
    const check = this.state.acceptPolicy;
    this.setState({ acceptPolicy: !check });
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const { updateData } = this.props;
      if (updateData) {
        const { id } = auth.getCurrentUser();
        delete data.password;
        delete data.propsact;
        delete data.propsreg;
        data["id"] = id;
        const response = await trackPromise(updateUser(data));
        if (response.status === 200) {
          Swal.fire(
            "¡Cambios Guardados!",
            "¡Inicia sesión para observar los cambios!",
            "success"
          );
          delete data.id;
          data["password"] = "xxxx";
          this.props.onUpdate(data);
        }
      } else {
        const response = await trackPromise(createUser(data));
        auth.loginWithJwt(response.headers["x-auth-token"]);
        window.location = "/";
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.usuario = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { updateData } = this.props;
    return (
      <Col md={updateData ? "12" : "4"}>
        {" "}
        <Card>
          <LoadingComponent />
          <h3 className="text-center">
            <b>{updateData ? "Modifica tus datos" : "Ingresa tus datos"}</b>
          </h3>
          <CardBody>
            <Form onSubmit={this.handleSubmit}>
              {this.renderInput("nombre", "Nombre", "text", "Ej. Pedro")}
              {this.renderInput("apellido", "Apellido", "text", "Ej. Perez")}
              {this.renderInput(
                "usuario",
                "Email",
                "text",
                "Ej. bigducktech@gmail.com"
              )}
              {updateData
                ? null
                : this.renderInput("password", "Password", "password")}
              {this.renderInput(
                "telefono",
                "Telefono (Opcional)",
                "text",
                "Ej. 123-456789-1911"
              )}
              {updateData ? null : (
                <div className="form-check text-center mb-1">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={this.state.acceptPolicy}
                      onChange={this.handleCheck}
                    />
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                    <a
                      href="https://fragoso09.github.io/capcitydocs.github.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      <b>
                        <u>Aceptar Terminos y Condiciones</u>
                      </b>
                    </a>
                  </label>
                </div>
              )}
              {!updateData && (
                <Button
                  block
                  className="btn-round btn-danger mt-2"
                  type="submit"
                  disabled={!this.state.acceptPolicy}
                >
                  Crear Usuario
                </Button>
              )}
              {updateData && (
                <Button
                  block
                  className="btn-round mt-2"
                  color="danger"
                  type="submit"
                >
                  Guardar Cambios
                </Button>
              )}
            </Form>
            {!updateData && (
              <NavLink
                href="#"
                className="text-center mt-1 text-primary"
                onClick={this.props.onProcessChg}
              >
                {" "}
                <h6>Iniciar Sesión</h6>
              </NavLink>
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default RegisterForm;

import React from "react";
import { trackPromise } from "react-promise-tracker";
import _ from "lodash";
import LzForm from "./lzForm";
import { Container, Card, CardBody, Form, Button, Label } from "reactstrap";
import Swal from "sweetalert2";
import Joi from "joi-browser";
import auth from "../../services/authService";
import { OrdinaryMap, getAddress, deleteMarkers } from "./ordinaryMap";
import { savePropiedad } from "../../services/propiedadesService";
import { uploadImage } from "../../services/uploadService";
import { getCiudades } from "../../services/namesMexico";
import { LoadingComponent } from "./loadingComponent";

class CreatePropertyForm extends LzForm {
  state = {
    data: {
      titulo: "",
      estado: "",
      ciudad: "",
      cp: "",
      colonia: "",
      calle: "",
      numero: "",
      metros: 0,
      precio: 0,
      lat: 0,
      lng: 0,
      tipo: "",
      usuario_id: 0,
      propiedad: "Terreno",
      fotos: [],
      agua: 0,
      drenaje: 0,
      luz: 0,
    },
    marcador: false,
    ciudades: [],
    errors: {},
  };

  schema = {
    titulo: Joi.string().required().label("Titulo"),
    estado: Joi.string().required().label("Estado"),
    ciudad: Joi.string().required().label("Ciudad"),
    cp: Joi.number().required().label("Codigo Postal"),
    colonia: Joi.string().required().label("Colonia"),
    calle: Joi.string().required().label("Calle"),
    numero: Joi.number().required().label("Numero"),
    metros: Joi.number().required().min(10).label("Metros"),
    precio: Joi.number().required().min(100).label("Precio"),
    lat: Joi.number().required().min(1).label("Latitud"),
    lng: Joi.number().required().label("Longitud"),
    tipo: Joi.string().required().label("Tipo"),
    usuario_id: Joi.number().optional().label("Id"),
    propiedad: Joi.string().required().label("Propiedad"),
    fotos: Joi.optional().label("Fotos"),
    id: Joi.optional().label("Id"),
    registro: Joi.optional().label("Registro"),
    agua: Joi.optional().label("Agua"),
    drenaje: Joi.optional().label("Drenaje"),
    luz: Joi.optional().label("Luz"),
    isAvailable: Joi.optional().label("isAvailable"),
    isDelete: Joi.optional().label("isDelete"),
    isActive: Joi.optional().label("isActive"),
    isActiveRegistro: Joi.optional().label("isActiveRegistro"),
  };

  async componentDidMount() {
    const { propiedad } = this.props;
    if (propiedad) {
      const { data: ciudades } = await getCiudades(propiedad[0].estado);
      this.setState({ data: propiedad[0], ciudades, marcador: true });
    } else {
      const { id } = auth.getCurrentUser();
      const data = { ...this.state.data };
      data["usuario_id"] = id;
      this.setState({ data });
    }
  }

  mapAddress = async () => {
    try {
      const params = _.pickBy(
        _.pick(this.state.data, [
          "estado",
          "ciudad",
          "cp",
          "colonia",
          "calle",
          "numero",
        ]),
        (v) => v
      );
      if (Object.keys(params).length < 5) {
        Swal.fire(
          "Error en Localización",
          "Completa tu dirección para poder localizar tu propiedad",
          "error"
        );
        return;
      }
      const { estado, ciudad, cp, colonia, calle, numero } = this.state.data;
      const direccion =
        calle +
        " " +
        numero +
        "," +
        colonia +
        "," +
        cp +
        " " +
        ciudad +
        "," +
        estado;
      const { marker, mapas } = await getAddress(direccion);
      const data = { ...this.state.data };
      data["lat"] = marker.getPosition().lat();
      data["lng"] = marker.getPosition().lng();
      this.setState({ data, marcador: true });
      mapas.event.addListener(marker, "dragend", (e) => {
        const data = { ...this.state.data };
        data["lat"] = marker.getPosition().lat();
        data["lng"] = marker.getPosition().lng();
        this.setState({ data });
      });
      Swal.fire(
        "Dirección Localizada",
        "Tu dirección esta en el mapa. Puedes arrastrar el pin para localizarla exactamente.",
        "success"
      );
    } catch (err) {
      Swal.fire(
        "Error en Localización",
        "La ubicación de pudo ser mapeada, envia un correo a info@capitolcity2.com para solicitar apoyo.",
        "error"
      );
    }
  };

  handleDelete = () => {
    deleteMarkers();
    const data = { ...this.state.data };
    data["lat"] = 0;
    data["lng"] = 0;
    this.setState({ data, marcador: false });
  };

  doSubmit = async () => {
    //Call the server
    try {
      const { data: datos } = this.state;
      const propiedad = this.props.propiedad;
      const fotos = new FormData();
      if (datos.fotos.length > 0 && !propiedad) {
        const arrFotos = datos.fotos;
        for (var x = 0; x < arrFotos.length; x++) {
          fotos.append("file", arrFotos[x]);
        }
        const upload = await trackPromise(uploadImage(fotos));
        if (upload["data"]) {
          const datosWFotos = { ...datos };
          datosWFotos["fotos"] = upload["data"].toString();
          await savePropiedad(datosWFotos);
        }
        Swal.fire({
          title: "Registro Completo",
          icon: "success",
          position: "top-start",
          showConfirmButton: false,
          timer: 1500,
        });
        this.props.onSubmit();
      } else if (!this.state.data.fotos.length) {
        Swal.fire(
          "Error en Fotos",
          "Tienes que seleccionar al menos una foto de la propiedad",
          "error"
        );
      } else {
        const datosWFotos = { ...datos };
        datosWFotos["fotos"] = datosWFotos["fotos"].toString();
        this.setState({ data: datosWFotos });
        await savePropiedad(datosWFotos);
        Swal.fire({
          title: "Propiedad Actualizada",
          icon: "success",
          position: "top-start",
          showConfirmButton: false,
          timer: 1500,
        });
        this.props.onSubmit();
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.usuario = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const propiedad = this.props.propiedad;
    return (
      <React.Fragment>
        {" "}
        <LoadingComponent />
        <Container>
          <Card
            className="mt-4"
            style={{
              border: "5px solid " + (!propiedad ? "#f5593d" : "#6bd098"),
            }}
          >
            <h4 className="text-center pt-1">
              <b>
                {propiedad
                  ? "Modifica tu propiedad"
                  : "Ingresa los datos de tu propiedad"}
              </b>
            </h4>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <div className="form-row">
                  <div className="col">
                    {this.renderInput(
                      "titulo",
                      "Nombre de la propiedad",
                      "text",
                      "Ej. Terreno en Santa Catarina"
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-4">
                    {this.renderSelect(
                      "estado",
                      "Estado",
                      this.props.estados,
                      1
                    )}
                  </div>
                  <div className="col-4">
                    {this.renderSelect("ciudad", "Ciudad", this.state.ciudades)}
                  </div>
                  <div className="col-4">
                    {this.renderInput("cp", "Código Postal")}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-4">
                    {this.renderInput("colonia", "Colonia")}
                  </div>
                  <div className="col-6">
                    {this.renderInput("calle", "Calle")}
                  </div>
                  <div className="col-2">
                    {this.renderInput("numero", "Num")}
                  </div>
                </div>
                <div className="form-row justify-content-center">
                  <div className="col-3">
                    {this.renderSelect("tipo", "Tipo", [
                      { id: 1, name: "Venta" },
                      { id: 2, name: "Renta" },
                    ])}
                  </div>
                  <div className="col-3">
                    {this.renderInput("metros", "Mts²", "", 10)}
                  </div>
                  <div className="col-3">
                    {this.renderInput("precio", "Precio", "number", "", 100000)}
                  </div>
                  <div className="col-3 justify-content-center">
                    <Label className="col">
                      <b>Localizar</b>
                    </Label>
                    <div className="col justify-content-center">
                      {" "}
                      <Button
                        type="button"
                        disabled={this.state.marcador}
                        className="btn-round btn-primary btn-sm"
                        onClick={this.mapAddress}
                      >
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </Button>
                      <Button
                        type="button"
                        disabled={!this.state.marcador}
                        className={
                          "btn-round btn-sm ml-2 " +
                          (this.state.marcador ? "btn-danger" : "btn-secondary")
                        }
                        onClick={this.handleDelete}
                      >
                        <i
                          className="fa fa-minus-circle"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </div>
                  </div>
                </div>

                <div className={"form-row " + (propiedad ? "d-none" : "")}>
                  {this.renderFileInput("fotos")}
                </div>
                <div className="form-row p-2 justify-content-center">
                  <div className="col-md-2 align-self-center">
                    <span>
                      <b>Servicios Disponibles:</b>
                    </span>
                  </div>
                  <div className="col-md-2">
                    {this.renderToggle("agua", "Agua")}
                  </div>
                  <div className="col-md-2">
                    {this.renderToggle("drenaje", "Drenaje")}
                  </div>
                  <div className="col-md-2">
                    {this.renderToggle("luz", "Luz")}
                  </div>
                </div>
                <div className="form-row pt-2" style={{ height: "350px" }}>
                  <OrdinaryMap
                    center={
                      !propiedad
                        ? { lat: 25.617847, lng: -100.287275 }
                        : { lat: propiedad[0].lat, lng: propiedad[0].lng }
                    }
                    zoom={15}
                    flag={!propiedad ? 0 : 1}
                  />
                </div>
                <div className="form-row justify-content-center mt-2">
                  {" "}
                  <Button
                    className="btn-round pt-2"
                    color={!propiedad ? "danger" : "success"}
                    type="submit"
                  >
                    {!propiedad ? "Alta de Propiedad" : "Guardar Cambios"}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default CreatePropertyForm;

import http from "./httpService";

export function checkContacto(propiedadId, usuarioId) {
  return http.get("/contacto/" + propiedadId + "/" + usuarioId);
}

export function getContacto(usuarioId) {
  return http.get("/contacto/" + usuarioId);
}

export function newContacto(body) {
  return http.post("/contacto", body);
}

export function updateContacto(body) {
  const contactoId = body.id;
  delete body.id;
  return http.put("/contacto/" + contactoId, body);
}

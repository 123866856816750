import React, { Component } from "react";
import Nouislider from "nouislider-react";
import _ from "lodash";

class ProfileFilter extends Component {
  state = {
    filter: {
      word: "",
      tipo: "",
      precioMin: 0,
      precioMax: 5,
      metrosMin: 0,
      metrosMax: 3000,
    },
  };

  handleChange = ({ currentTarget: input }) => {
    const filter = { ...this.state.filter };
    filter[input.name] = input.value;
    this.setState({ filter });
    this.filterCartera(filter);
  };

  filterCartera = (data) => {
    const cartera = this.props.carteraInfo;
    let filter = data.tipo ? _.filter(cartera, { tipo: data.tipo }) : cartera;
    filter = data.word
      ? _.filter(filter, function (o) {
          return (
            _.startsWith(o.nombre.toLowerCase(), data.word.toLowerCase()) ||
            _.startsWith(
              o.apellido.toLowerCase(),
              data.word.toLowerCase() || _.startsWith(o.usuario, data.word)
            )
          );
        })
      : filter;
    filter =
      data.precioMax > 0
        ? _.filter(
            filter,
            this.predicate(
              data.precioMin * 1000000,
              data.precioMax * 1000000 + 1,
              "avgPrecio"
            )
          )
        : filter;
    filter =
      data.metrosMax > 0
        ? _.filter(
            filter,
            this.predicate(data.metrosMin, data.metrosMax + 1, "avgMetros")
          )
        : filter;
    filter = data.precioMax === 0 || data.metrosMax === 0 ? [] : filter;
    this.props.onFilter(filter);
    return;
  };

  predicate = (start, end, name) => {
    return (cartera) => {
      return _.inRange(cartera[name], start, end);
    };
  };

  handleSlide = (value) => {
    const filter = { ...this.state.filter };
    filter["precioMin"] = value[0];
    filter["precioMax"] = value[1];
    this.setState({ filter });
    this.filterCartera(filter);
  };

  handleSlideMts = (value) => {
    const filter = { ...this.state.filter };
    filter["metrosMin"] = value[0];
    filter["metrosMax"] = value[1];
    this.setState({ filter });
    this.filterCartera(filter);
  };

  render() {
    const {
      word,
      tipo,
      precioMin,
      precioMax,
      metrosMin,
      metrosMax,
    } = this.state.filter;
    return (
      <React.Fragment>
        <div className="col-md-3">
          <label>
            <b>Nombre/Apellido o Correo:</b>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Escribe tu busqueda"
            onChange={this.handleChange}
            value={word}
            name={"word"}
          />
        </div>
        <div className="col-md-2">
          <label>
            <b>Tipo:</b>
          </label>
          <select
            onChange={this.handleChange}
            name={"tipo"}
            value={tipo}
            className="form-control"
          >
            <option value=""></option>
            <option value="Venta">Venta</option>
            <option value="Renta">Renta</option>
          </select>
        </div>
        <div className="col-md-3 ml-2">
          <label className="mb-3">
            <b>
              De: ${precioMin}MM a ${precioMax}
              MM
            </b>
          </label>
          <Nouislider
            range={{ min: 0, max: 5 }}
            step={0.1}
            start={[0, 5]}
            connect
            onSlide={this.handleSlide}
            style={{ margin: "0" }}
          />
        </div>
        <div className="col-md-3 ml-2">
          <label className="mb-3">
            <b>
              De: {parseInt(metrosMin)}Mts² a&nbsp;
              {parseInt(metrosMax)}Mts²
            </b>
          </label>
          <Nouislider
            range={{ min: 0, max: 3000 }}
            step={10}
            start={[0, 3000]}
            connect
            onSlide={this.handleSlideMts}
            style={{ margin: "0" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileFilter;

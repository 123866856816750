import http from "./httpService";

export function checkOutSession(propertyId) {
  const body = {
    product: process.env.REACT_APP_STRIPE_PROD,
    propertyId: propertyId,
  };
  return http.post("/checkout", body);
}

export function checkOutProduct(prod, body) {
  return http.post("/checkout/" + prod + "/stripe", body);
}

export function payPalMail(body) {
  return http.post("/checkout/paypal", body);
}

import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

const DoughnutChart = (props) => {
  const { data, colors, labels, title } = props;
  return (
    <React.Fragment>
      <Doughnut
        data={{
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
              hoverBorderColor: "rgba(0, 17, 28, 0.4)",
            },
          ],
          labels: labels,
        }}
        width={100}
        height={200}
        options={{
          maintainAspectRatio: false,
          legend: { position: "bottom" },
          title: { display: true, text: title },
          tooltips: { enabled: false },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                  return true;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
              },
              display: true,
              align: "end",
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export default DoughnutChart;

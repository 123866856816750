import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import IndexNavbar from "./indexNavbar";
import auth from "../services/authService";
import LoginForm from "./common/loginForm";
import RegisterForm from "./common/registerForm";
import inicio from "../assets/img/saltillo_4.png";

class CredentialsEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      process: 1,
    };
    this.hanldeRegister = this.hanldeRegister.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.flag) {
      this.setState({ process: 2 });
    }
  }

  hanldeRegister = ({ currentTarget }) => {
    const process = currentTarget.name === "registrar" ? 2 : 1;
    this.setState({ process });
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;
    return (
      <React.Fragment>
        <IndexNavbar clase="fixed-top" flag={true} />
        <img src={inicio} alt="fondo-inicio" className="img-login"></img>
        <div className="page-header section-dark">
          <Container>
            <Row className="justify-content-center pt-5">
              {this.state.process === 1 && (
                <LoginForm
                  location={this.props.location}
                  onProcessChg={this.hanldeRegister}
                />
              )}
              {this.state.process === 2 && (
                <RegisterForm
                  location={this.props.location}
                  onProcessChg={this.hanldeRegister}
                />
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CredentialsEntry;

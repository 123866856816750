import React, { Component } from "react";
import { Input, FormGroup, Label } from "reactstrap";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import Toggle from "react-toggle";
import { getCiudades } from "../../services/namesMexico";

class LzForm extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    //console.log(errors);
    if (errors) {
      if (errors.lat) {
        Swal.fire({
          icon: "error",
          title: "Tienes que localizar tu propiedad",
          html:
            "<p><b>Haz click en el botón azul de localizar para mapear tu propiedad</b></p>",
        });
      }
      return;
    }
    this.doSubmit();
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    if (!error) return null;
    return error.details[0].message;
  };

  callCiudades = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.value) {
      const { data: ciudades } = await getCiudades(input.value);
      this.setState({ data, ciudades });
    } else {
      this.setState({ data, ciudades: [] });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleService = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = !data[input.name];
    this.setState({ data });
  };

  maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 3) {
      return false;
    }
    return true;
  };

  checkMimeType = (event) => {
    let files = event.target.files;
    let err = "";
    const types = ["image/png", "image/jpeg"];
    for (let x = 0; x < files.length; x++) {
      if (types.every((type) => files[x].type !== type)) {
        err += files[x].type + " is not a supported format\n";
      }
    }
    if (err !== "") {
      return false;
    }
    return true;
  };

  checkFileSize = (event) => {
    let files = event.target.files;
    let size = 5500000;
    let err = "";
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err += files[x].type + "is too large, please pick a smaller file\n";
      }
    }
    if (err !== "") {
      return false;
    }

    return true;
  };

  handleChangeFile = (event) => {
    const data = { ...this.state.data };
    if (
      this.maxSelectFile(event) &&
      this.checkMimeType(event) &&
      this.checkFileSize(event)
    ) {
      data["fotos"] = event.target.files;
      this.setState({ data });
    } else {
      Swal.fire({
        icon: "error",
        title: "Archivos Invalidos",
        html:
          "<li><b>Maximo 3 imagenes</b</li><li><b>Formatos validos: .JPEG, .PNG</b></li><li><b>El tamaño de cada imagen no debe pasar de 5MB</b></li>",
      });
    }
  };

  renderInput(
    name,
    label,
    type = "text",
    pholder = "",
    step = 0,
    errorMsg = "Campo Invalido"
  ) {
    const { data, errors } = this.state;
    return (
      <FormGroup className={errors[name] && "has-danger"}>
        <Label>
          <b>{label}</b>
        </Label>
        <Input
          type={type}
          name={name}
          value={data[name]}
          placeholder={pholder}
          onChange={this.handleChange}
          step={step}
        />
        {name === "numero" ? (
          <small style={{ fontSize: "10px" }}>
            <b>*En caso de no tener, capturar 0</b>
          </small>
        ) : null}
        {name === "numeroModal" ? (
          <small style={{ fontSize: "10px" }}>
            <b>*Opcional</b>
          </small>
        ) : null}
        {name === "cp" ? (
          <small style={{ fontSize: "10px" }}>
            <a
              href="https://micodigopostal.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontWeight: "bolder", color: "#000000" }}>
                <b>www.micodigopostal.org</b>
              </span>
            </a>
          </small>
        ) : null}
        {errors[name] ? (
          <div className="form-control-feedback">
            <b>{errorMsg}</b>
          </div>
        ) : null}
      </FormGroup>
    );
  }

  renderToggle(name, label) {
    const { data } = this.state;
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <Label className="label-text mr-1 mt-2">
            <b>{label}</b>
          </Label>
          <Toggle
            className="align-self-center"
            name={name}
            checked={data[name] ? true : false}
            onChange={this.handleService}
          />
        </div>
      </React.Fragment>
    );
  }

  renderFileInput(name) {
    const { data } = this.state;
    return (
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          lang="es"
          onChange={this.handleChangeFile}
          multiple
        />
        <label className="custom-file-label" htmlFor="customFile">
          {data[name].length} Fotos Seleccionadas (Máximo 3 Fotos)
        </label>
        <span style={{ fontWeight: "bolder" }}>
          <small>
            <b>*Selecciona el conjunto de fotos</b>
          </small>
        </span>
      </div>
    );
  }

  renderSelect(name, label, options, flag) {
    const { data, errors } = this.state;
    return (
      <FormGroup className={errors[name] && "has-danger"}>
        <Label>
          <b>{label}</b>
        </Label>
        <select
          onChange={flag ? this.callCiudades : this.handleChange}
          name={name}
          id={name}
          value={data[name]}
          className="form-control"
        >
          <option value=""></option>
          {options.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </FormGroup>
    );
  }
}

export default LzForm;

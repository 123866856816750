import React, { Component } from "react";
import _ from "lodash";
import wNumb from "wnumb";

class TableBodyLz extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    if (column.path === "avgPrecio" || column.path === "avgMetros") {
      const speacialFormat = wNumb({
        mark: ".",
        thousand: ",",
        prefix: column.path === "avgPrecio" ? "$ " : "",
      });
      const valor = _.get(item, column.path);
      return speacialFormat.to(valor);
    }
    return _.get(item, column.path);
  };

  render() {
    const { data, columns } = this.props;
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            {columns.map((column) => (
              <td key={item.id + (column.path || column.key)}>
                <b>{this.renderCell(item, column)}</b>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBodyLz;

import React, { Component } from "react";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { Row, Container } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import IndexNavbar from "./indexNavbar";
import CreatePropertyForm from "./common/createPropertyForm";
import LzPagination from "./common/lzPagination";
import LzCard from "./common/lzCard";
import PaymentModal from "./common/paymentModal";
import auth from "../services/authService";
import { LoadingComponent } from "./common/loadingComponent";
import { OrdinaryMap } from "./common/ordinaryMap";
import {
  getPropiedades,
  getPropiedad,
  deletePropiedad,
  availablePropiedad,
  activePropiedad
} from "../services/propiedadesService";
import { checkOutSession } from "./../services/checkOutService";
import { getEstados } from "../services/namesMexico";
import { paginate } from "./../utils/paginate";

class PropertyUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propiedades: [],
      estados: [],
      currentPage: 1,
      pageSize: 6,
      insertar: false,
      updateProp: null,
      mapa: false,
      modal: false,
      propertyToPay: 0,
      usuario: "",
    };
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    const { id: userId, usuario } = auth.getCurrentUser();
    const { data: propiedades } = await trackPromise(
      getPropiedades("propiedades", userId)
    );
    const { data: estados } = await getEstados();
    const insertar = this.props.match.params.flag === "1" ? true : false;
    this.setState({ propiedades, estados, insertar, usuario });
  }

  async handleUpdate() {
    const { id: userId } = auth.getCurrentUser();
    const { data: propiedades } = await getPropiedades("propiedades", userId);
    this.setState({ propiedades, insertar: false });
  }

  handleReview = async (propertyId) => {
    const { data: updateProp } = await getPropiedad(propertyId);
    updateProp[0]["fotos"] = updateProp[0]["fotos"].split(",");
    const insertar = true;
    this.setState({ updateProp, insertar });
  };

  handleActive = (propertyId) => {
    Swal.fire({
      title: "¿Quieres activar esta propiedad?",
      html:
        "<ul style='text-align:justify'><li><p><b>Es completamente gratis</b></p></li><li><p><b>Se puede desactivar en cualquier momento en caso de que ya no se encuentre disponible la propiedad o simplemente quiera dejar de publicarla. Solamente con marcarla como disponible o vendida/rentada</b></p></li></ul>",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        //Pago temporalmente dehabilitado. Propiedades gratis para publicar.
        //this.setState({ modal: true, propertyToPay: propertyId });


        /* Segmento para activar a propiedad gratis */
        const body = {
          isActive: true,
          isAvailable: true,
          subCode: "Free",
          subType: "Free",
        };
        const result = await trackPromise(activePropiedad(propertyId, body));
        if (result.status === 200) {
          Swal.fire({
            title: "¡Listo!",
            text: "Tu propiedad se encuentra pública",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          const { data: propiedades } = await trackPromise(
            getPropiedades("propiedades", this.props.user.id)
          );
          this.setState({ propiedades });
        }

        /* Segmento para activar a propiedad gratis */
      }
    });
  };

  handlePrivate = async (propertyId, isActive) => {
    Swal.fire({
      title: "¿Quieres marcar como privada esta propiedad?",
      icon: "question",
      html:
        "<ul style='text-align:left'><li><p><b>Tu propiedad aparecerá como privada</b></p></li>" +
        (isActive
          ? "<li><p><b>Tu publicación dejará de estar disponible para los clientes</b></p></li>"
          : "<li><p><b>Podrás volver a publicarla sin ningún costo</b></p></li>") +
        "</ul>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        const res = await trackPromise(availablePropiedad(propertyId, 1));
        if (res.status === 200) {
          Swal.fire({
            title: "¡Listo!",
            text: "Tu propiedad aparecera como privada",
            icon: "success",
          });
          const { data: propiedades } = await trackPromise(
            getPropiedades("propiedades", this.props.user.id)
          );
          this.setState({ propiedades });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ups...",
            text:
              "¡Algo salio mal! Intenta de nuevo o escribe un correo para revisar este caso.",
          });
        }
      }
    });
  };

  handleAvailable = async (propertyId, tipo) => {
    Swal.fire({
      title:
        "¿Quieres marcar como " +
        (tipo === "Venta" ? "vendida" : "rentada") +
        " esta propiedad?",
      icon: "question",
      html:
        "<ul style='text-align:left'><li><p><b>La propiedad no podrá ser vista por ningún cliente</b></p></li><li><p><b>Podrás volver a publicarla sin ningún costo</b></p></li></ul>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        const res = await trackPromise(availablePropiedad(propertyId, 0));
        if (res.status === 200) {
          Swal.fire({
            title: "¡Felicidades!",
            text:
              "Tu propiedad fue " + (tipo === "Venta" ? "vendida" : "rentada"),
            icon: "success",
            footer:
              tipo === "Venta"
                ? ""
                : "Puedes activar tu propiedad de nuevo en cuanto este disponible",
          });
          const { data: propiedades } = await trackPromise(
            getPropiedades("propiedades", this.props.user.id)
          );
          this.setState({ propiedades });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ups...",
            text:
              "¡Algo salio mal! Intenta de nuevo o escribe un correo para revisar este caso.",
          });
        }
      }
    });
  };

  handleDelete = async (propertyId) => {
    Swal.fire({
      title: "¿Quieres borrar esta propiedad?",
      icon: "warning",
      html:
        "<ul style='text-align:left'><li><p><b>En caso de tener la propiedad activa ya no se mostrará a ningún cliente</b></p></li></ul>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        const res = await trackPromise(deletePropiedad(propertyId));
        if (res.status === 200) {
          Swal.fire("¡Propiedad Eliminada!", "", "success");
          const { data: propiedades } = await trackPromise(
            getPropiedades("propiedades", this.props.user.id)
          );
          this.setState({ propiedades });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ups...",
            text:
              "¡Algo salio mal! Intenta de nuevo o escribe un correo para revisar este caso.",
          });
        }
      }
    });
  };

  handleCredit = async (propertyId) => {
    const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHED_KEY);
    const result = await trackPromise(checkOutSession(propertyId));
    if (result.status === 200) {
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId: result.data.id });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleShowMap = () => {
    const mapa = !this.state.mapa;
    this.setState({ mapa });
  };

  handleShowWindow = () => {
    const insertar = !this.state.insertar;
    this.setState({ insertar, updateProp: null });
  };

  handleModal = () => {
    const modal = this.state.modal;
    this.setState({ modal: !modal, propertyToPay: 0 });
  };

  getPagedData = () => {
    const { currentPage, pageSize, propiedades } = this.state;
    const result = paginate(propiedades, currentPage, pageSize);
    return { totalCount: propiedades.length, result };
  };

  render() {
    const { totalCount, result } = this.getPagedData();
    const {
      pageSize,
      currentPage,
      insertar,
      propiedades,
      mapa,
      modal,
      propertyToPay,
      usuario,
    } = this.state;
    const center = this.state.propiedades.length
      ? {
          lat: this.state.propiedades[0].lat,
          lng: this.state.propiedades[0].lng,
        }
      : { lat: 25.617544, lng: -100.287534 };
    const zoom = 13;
    return (
      <React.Fragment>
        <IndexNavbar
          clase="sticky-top"
          user={this.props.user}
          page={this.props.match.path}
        />
        <PaymentModal
          modal={modal}
          onToggle={this.handleModal}
          propertyId={propertyToPay}
          usuario={usuario}
          onCredit={this.handleCredit}
          onUpdate={this.handleUpdate}
        />
        <LoadingComponent />
        <div className="row">
          <div className="container">
            <div className="row justify-content-between">
              <h3 className="title ml-3">
                <b>Tus propiedades</b>
              </h3>
              <div>
                {!insertar && (
                  <button
                    className={
                      "btn btn-round align-self-center mt-4 mr-2 " +
                      (mapa ? "btn-default" : "btn-primary")
                    }
                    style={{ height: "50%" }}
                    onClick={this.handleShowMap}
                  >
                    {mapa ? "Ocultar Mapa" : "Mostar Mapa"}
                  </button>
                )}
                <button
                  className={
                    "btn btn-round align-self-center mt-4 " +
                    (insertar ? "btn-default" : "btn-danger")
                  }
                  style={{ height: "50%" }}
                  onClick={this.handleShowWindow}
                >
                  {insertar ? "Regresar" : "Agregar Propiedad"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ fontSize: "14px" }}
        >
          <span>
            <i className="fa fa-globe" aria-hidden="true" />
            <b>Estado</b>
          </span>
          <span className="margin-icon">
            <i className="fa fa-location-arrow" aria-hidden="true" />
            <b>Ciudad</b>
          </span>
          <span className="margin-icon">
            <i className="fa fa-map-marker" aria-hidden="true" />
            <b>Colonia</b>
          </span>
          <span className="margin-icon">
            <i className="fa fa-road" aria-hidden="true" />
            <b>Calle</b>
          </span>
          <span className="margin-icon">
            <i className="fa fa-file-text" aria-hidden="true" />
            <b>Servicios</b>
          </span>
          <span className="margin-icon">
            <i className="fa fa-arrows-h" aria-hidden="true" />
            <b>Mts²</b>
          </span>
          <span className="margin-icon">
            <i className="fa fa-money" aria-hidden="true" />
            <b>Precio</b>
          </span>
        </div>
        <Row className="px-3">
          {mapa && (
            <React.Fragment>
              {" "}
              <div className="container">
                <div className="row px-3" style={{ height: "400px" }}>
                  <OrdinaryMap
                    center={center}
                    zoom={zoom}
                    flag={2}
                    propiedades={propiedades}
                  />
                </div>
              </div>{" "}
            </React.Fragment>
          )}
          <div className="col-md-12">
            {!insertar && (
              <Container>
                <div className="row pt-3">
                  {result.length ? (
                    result.map((item) => (
                      <LzCard
                        key={item.id}
                        data={item}
                        size={insertar}
                        onReview={this.handleReview}
                        onActive={this.handleActive}
                        onDelete={this.handleDelete}
                        onAvailable={this.handleAvailable}
                        onPrivate={this.handlePrivate}
                      />
                    ))
                  ) : (
                    <h2 className="col text-center">
                      No tienes propiedades registradas
                    </h2>
                  )}
                </div>
                <div className="row justify-content-center">
                  <LzPagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </Container>
            )}
          </div>
          {insertar && (
            <div className="col-md-12">
              <CreatePropertyForm
                user={this.props.user}
                onSubmit={this.handleUpdate}
                estados={this.state.estados}
                propiedad={this.state.updateProp}
              />
            </div>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default PropertyUpdate;

import http from "./httpService";

export function getRoads(userId) {
  return http.get("/trafico/" + userId);
}

export function reqTrafico(body) {
  return http.post("/trafico", body);
}

export function updatePayPalTrafico(body) {
  return http.post("/trafico/paypal", body);
}

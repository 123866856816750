import React, { Component } from "react";
import wNumb from "wnumb";
import LzCardMap from "./lzCardMap";

class PropertyPin extends Component {
  state = {
    infoWindow: false,
  };

  showInfoWindow = () => {
    const infoWindow = this.state.infoWindow;
    this.setState({ infoWindow: !infoWindow });
  };

  render() {
    const { precio, hoverId, id, usuario, actualUserId, metros } = this.props;
    const { infoWindow } = this.state;
    const moneyFormat = wNumb({
      mark: ".",
      thousand: ",",
      prefix: "$",
      suffix: " m²",
    });
    const commaFormat = wNumb({
      thousand: ",",
    });
    const preciomt = parseInt(precio / metros);

    return (
      <React.Fragment>
        <span
          onClick={this.showInfoWindow}
          className={"badge badge-pill"}
          style={{
            width: "90px",
            height: "35px",
            fontSize: "12px",
            fontWeight: "bolder",
            textAlign: "center",
            textTransform: "none",
            color: "white",
            cursor: "pointer",
            border: "1px solid black",
            backgroundColor:
              hoverId === id
                ? "rgba(186, 56, 0, 1)"
                : usuario === actualUserId
                ? "rgba(117, 201, 255, 1)"
                : "rgba(255, 86, 0, 1)",
          }}
        >
          {commaFormat.to(metros)} mts² <br />
          {moneyFormat.to(preciomt)}
        </span>
        {infoWindow && (
          <div
            style={{
              width: 250,
              height: 250,
            }}
          >
            <LzCardMap data={this.props} flag={true} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default PropertyPin;

/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import App from "./app";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import "font-awesome/css/font-awesome.css";
import "assets/css/main.css";
//import "nouislider/distribute/nouislider.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";
import FooterBD from "components/footerBd";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.Fragment>
    <div className="page-container">
      <div className="content-wrap">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </div>
      <FooterBD />
    </div>
  </React.Fragment>,
  document.getElementById("root")
);

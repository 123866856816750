import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import LzCarousel from "./lzCarousel";
import wNumb from "wnumb";
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import { postFavorito, delFavorito } from "../../services/favoritoService";

class PropertyRow extends Component {
  state = { favorito: 0 };

  componentDidMount = () => {
    this.setState({ favorito: this.props.data.favorito });
  };

  handleMouseEnter = () => {
    this.props.onHoverEnter(this.props.data.id);
  };

  handleMouseLeave = () => {
    this.props.onHoverExit();
  };

  handleFavorito = async () => {
    const user = auth.getCurrentUser();
    const { favorito } = this.state;
    const body = { usuario_id: user.id, propiedad_id: this.props.data.id };
    const result = favorito
      ? await delFavorito(this.props.data.id, user.id)
      : await postFavorito(body);
    if (result.status === 200) {
      this.setState({ favorito: !favorito });
    }
  };

  render() {
    const {
      estado,
      ciudad,
      colonia,
      calle,
      numero,
      propiedad,
      tipo,
      precio,
      metros,
      id,
      agua,
      drenaje,
      luz,
      registro,
    } = this.props.data;

    const { favorito } = this.state;

    const fecha1 = new Date(registro);
    const fecha2 = new Date();
    const diffTime = Math.abs(fecha2 - fecha1);
    const diffMin = Math.floor(diffTime / (1000 * 60));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const moneyFormat = wNumb({
      mark: ".",
      thousand: ",",
      prefix: "$ ",
    });
    const commaFormat = wNumb({
      thousand: ",",
    });
    const precioMetro = parseInt(precio / metros);

    return (
      <Col className=" col-12 p-1 border-bottom">
        {diffDays <= 30 ? (
          <div className="ribbon ribbon-top-right">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;NUEVO</span>
          </div>
        ) : null}
        <Row
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={{ marginRight: "-15px", marginLeft: "-15px" }}
        >
          <Col md="5">
            <LzCarousel fotos={this.props.data.fotos.split(",")} />
            <i
              className={favorito ? "fa fa-heart fa-lg" : "fa fa-heart-o fa-lg"}
              aria-hidden="true"
              onClick={this.handleFavorito}
              style={{
                cursor: "pointer",
                color: favorito ? "rgb(192,0,0)" : "black",
              }}
            />
          </Col>
          <Col md="7">
            <Link
              to={`/maps/${id}`}
              target="_blank"
              style={{ color: "black", textDecoration: "none !important" }}
            >
              <Row>
                <h5>
                  <b>
                    {propiedad} en {tipo}
                  </b>
                </h5>
              </Row>
              <Row>
                <span>
                  <i className="fa fa-globe" aria-hidden="true"></i>
                  &nbsp;
                  <b>{estado}</b>
                </span>
              </Row>
              <Row>
                <span>
                  <i className="fa fa-location-arrow" aria-hidden="true"></i>
                  &nbsp;
                  <b>{ciudad}</b>
                </span>
              </Row>
              <Row>
                <span>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  &nbsp;
                  <b>{colonia}</b>
                </span>
              </Row>
              <Row>
                <span>
                  <i className="fa fa-road" aria-hidden="true"></i>&nbsp;
                  <b>
                    {calle} {numero > 0 ? "# " + numero : ""}
                  </b>
                </span>
              </Row>
              <Row>
                <span>
                  <i className="fa fa-file-text" aria-hidden="true" />
                  &nbsp;
                  <b>
                    Agua
                    <i
                      className={agua ? "fa fa-check" : "fa fa-times"}
                      aria-hidden="true"
                      style={{ color: agua ? "#32a852" : "#f7765f" }}
                    />{" "}
                    Drenaje
                    <i
                      className={drenaje ? "fa fa-check" : "fa fa-times"}
                      aria-hidden="true"
                      style={{ color: drenaje ? "#32a852" : "#f7765f" }}
                    />{" "}
                    Luz{" "}
                    <i
                      className={luz ? "fa fa-check" : "fa fa-times"}
                      aria-hidden="true"
                      style={{ color: luz ? "#32a852" : "#f7765f" }}
                    />{" "}
                  </b>
                </span>
              </Row>
              <Row className="mt-2">
                <span style={{ fontWeight: "bolder" }}>
                  <i className="fa fa-arrows-h" aria-hidden="true" />
                  &nbsp;
                  <b>{commaFormat.to(metros)}</b>
                </span>
              </Row>
              <Row className="">
                <span style={{ fontWeight: "bolder" }}>
                  <i className="fa fa-money" aria-hidden="true" /> &nbsp;
                  <b>
                    {moneyFormat.to(precio)} ({moneyFormat.to(precioMetro)} m²)
                  </b>
                </span>
              </Row>
              <Row className="justify-content-end">
                <small className="d-block font-weight-normal">
                  Publicado hace:{" "}
                  {diffMin >= 60
                    ? diffHours >= 24
                      ? diffDays + (diffDays === 1 ? " día" : " días")
                      : diffHours + (diffHours === 1 ? " hora" : " horas")
                    : diffMin + (diffMin === 1 ? " minuto" : " minutos")}
                </small>
              </Row>
            </Link>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default PropertyRow;

import React, { Component } from "react";
import IndexNavbar from "./indexNavbar";
import auth from "../services/authService";

class PricingModule extends Component {
  state = { user: {} };

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    return (
      <React.Fragment>
        <IndexNavbar clase="sticky-top" user={this.state.user} flag={true} />
        <div className="pricing-header px-1 py-1 pt-md-3 pb-md-3 mx-auto text-center">
          <h1 className="display-4">Servicios Capitol City</h1>
        </div>

        <div className="container">
          <div className="card-deck mb-3 text-center">
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">
                  Administrar Propiedades
                </h4>
              </div>
              <div className="card-body d-flex flex-column">
                <h1
                  className="card-title pricing-card-title"
                  style={{ fontWeight: "bold" }}
                >
                  Gratis
                </h1>
                <ul
                  className="mt-3 mb-4 text-left"
                  style={{ fontWeight: "bold" }}
                >
                  <li>Agregar propiedades sin limite</li>
                  <li>Subir fotos y ubicación exacta de cada propiedad</li>
                  <li>Comparar tus propiedades con el mercado</li>
                </ul>
                <a
                  className="btn btn-lg btn-block btn-danger mt-auto"
                  href="/propiedades/1"
                >
                  Agregar Propiedad
                </a>
                <small>
                  <b>Aplican Terminos y Condiciones</b>
                </small>
              </div>
            </div>
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">
                  Publicar Propiedades
                </h4>
              </div>
              <div className="card-body d-flex flex-column">
                <h1
                  className="card-title pricing-card-title"
                  style={{ fontWeight: "bold" }}
                >
                  $289
                  <small className="text-muted" style={{ fontWeight: "bold" }}>
                    {" "}
                    MXN/mes
                  </small>
                </h1>
                <ul
                  className="mt-3 mb-4 text-left"
                  style={{ fontWeight: "bold" }}
                >
                  <li>Publicación para todos los usuarios de Capitol City</li>
                  <li>
                    Contacto por correo, teléfono o en tu inbox de Capitol City
                  </li>
                  <li>
                    A partir de tu quinta propiedad publicada el costo es de
                    $259 MXN/mes
                  </li>
                </ul>
                <a
                  className="btn btn-lg btn-block btn-danger mt-auto"
                  href="/propiedades"
                >
                  Publicar
                </a>
                <small>
                  <b>Aplican Terminos y Condiciones</b>
                </small>
              </div>
            </div>
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Analisis Premium</h4>
              </div>
              <div className="card-body d-flex flex-column">
                <h1
                  className="card-title pricing-card-title"
                  style={{ fontWeight: "bold" }}
                >
                  $589
                  <small className="text-muted" style={{ fontWeight: "bold" }}>
                    {" "}
                    MXN
                  </small>
                </h1>
                <ul
                  className="mt-3 mb-4 text-left"
                  style={{ fontWeight: "bold" }}
                >
                  <li>Analisis de altitud de la zona o colonia.</li>
                  <li>Comparación contra el valor del mercado.</li>
                  <li>Analisis de trafico en calles cercanas.</li>
                  <li>Puntos de interes cercanos.</li>
                  <li>Nivel socioeconomico.</li>
                  <li>Resultados en 48 hrs.</li>
                </ul>
                <a
                  className="btn btn-lg btn-block btn-secondary mt-auto"
                  href="/#lz"
                >
                  Solicitar
                </a>
                <small>
                  <b>Aplican Terminos y Condiciones</b>
                </small>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PricingModule;

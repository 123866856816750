import React from "react";
import GoogleMapReact from "google-map-react";
import _ from "lodash";

let mapa = {};
let mapas = {};
let markers = [];
let isoPol = [];

const getAddress = (address) => {
  return new Promise((resolve, reject) => {
    const geocoder = new mapas.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        let location = results[0].geometry.location;
        mapa.setCenter(location);
        mapa.setZoom(20);
        let marker = new mapas.Marker({
          position: location,
          draggable: true,
          mapa,
        });
        marker.setMap(mapa);
        markers.push(marker);
        resolve({ marker, mapas });
      } else {
        reject(status);
      }
    });
  });
};

const deleteMarkers = () => {
  if (markers.length >= 1) {
    markers[0].setMap(null);
  }
  markers = [];
};

const delPoly = () => {
  for (let i = 0; i < isoPol.length; i++) {
    isoPol[i].setMap(null);
  }
  let legend = document.getElementById("legend");
  legend.classList.remove("d-block");
  legend.classList.add("d-none");
};

const reDrawPoly = () => {
  for (let i = 0; i < isoPol.length; i++) {
    isoPol[i].setMap(mapa);
  }
  let legend = document.getElementById("legend");
  legend.classList.remove("d-none");
  legend.classList.add("d-block");
};

const drawPoly = (coords) => {
  let polygon = [];
  let color = "";
  for (let i = 0; i < coords.length; i++) {
    if (i === 0) {
      color = "#0FB519";
    } else if (i === 1) {
      color = "#c51b8a";
    } else {
      color = "#1f78b4";
    }
    polygon = new mapas.Polygon({
      paths: coords[i],
      strokeColor: color,
      strokeOpacity: 0.9,
      strokeWeight: 5,
      fillColor: color,
      fillOpacity: 0.1,
    });
    mapa.setZoom(13);
    polygon.setMap(mapa);
    isoPol.push(polygon);
  }
  let legend = document.getElementById("legend");
  legend.className = "m-4 p-2 d-block";
  legend.style.border = "5px solid black";
  legend.style.borderRadius = "20px";
  legend.style.backgroundColor = "white";
  legend.innerHTML =
    "<p><b>Tiempos en carro</b></p><p><i class='fa fa-circle' aria-hidden='true' style='color:#0FB519'></i>&nbsp;<b>5 Min</b></p><p><i class='fa fa-circle' aria-hidden='true' style='color:#c51b8a'></i>&nbsp;<b>7 Min</b></p><p><i class='fa fa-circle' aria-hidden='true' style='color:#1f78b4'></i>&nbsp;<b>10 Min</b><p>";
  mapa.controls[mapas.ControlPosition.LEFT_BOTTOM].push(legend);
};

const handleApiLoaded = (map, maps, flag, center, propiedades) => {
  mapa = map;
  mapas = maps;
  mapa.setMapTypeId(mapas.MapTypeId.HYBRID);
  if (flag === 1) {
    let marker = new mapas.Marker({
      position: center,
      mapa,
    });
    marker.setMap(mapa);
    mapa.setZoom(flag === 1 ? 15 : 20);
    markers.push(marker);
  } else if (flag === 2) {
    const infowindow = new mapas.InfoWindow();
    for (let i = 0; i < propiedades.length; i++) {
      const marker = new mapas.Marker({
        position: _.pick(propiedades[i], ["lat", "lng"]),
        mapa,
      });
      marker.addListener("click", (mapa) => {
        infowindow.close();
        infowindow.setContent(
          "<p style=font-size:13px><b>" + propiedades[i].titulo + "</b></p>"
        );
        infowindow.open(mapa, marker);
      });
      marker.setMap(mapa);
      markers.push(marker);
    }
  }
};

const OrdinaryMap = (props) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
      center={props.center}
      zoom={props.zoom}
      options={{
        gestureHandling: "greedy",
        streetViewControl: true,
        styles: [
          {
            featureType: "all",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
        ],
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) =>
        handleApiLoaded(map, maps, props.flag, props.center, props.propiedades)
      }
    >
      <React.Fragment>
        <div id="legend" style={{ width: "100px", fontSize: "10px" }}></div>
      </React.Fragment>
    </GoogleMapReact>
  );
};

export {
  OrdinaryMap,
  getAddress,
  deleteMarkers,
  drawPoly,
  delPoly,
  reDrawPoly,
};

import React, { Component } from "react";
//import { Link } from "react-router-dom";
import _ from "lodash";
import TableHeaderLz from "./tableHeaderLz";
import TableBodyLz from "./tableBodyLz";

class TableLz extends Component {
  state = { sortColumn: { path: "avgPrecio", order: "desc" } };

  columns = [
    { path: "nombre", label: "Nombre" },
    { path: "apellido", label: "Apellido" },
    { path: "usuario", label: "Correo" },
    { path: "telefono", label: "Telefono" },
    { path: "avgPrecio", label: "Precio Promedio" },
    { path: "avgMetros", label: "Metros Promedio" },
    { path: "tipo", label: "Tipo" },
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  getSortedData = () => {
    const { sortColumn } = this.state;
    const { cartera } = this.props;

    const sorted = _.orderBy(cartera, [sortColumn.path], [sortColumn.order]);

    return { data: sorted };
  };

  render() {
    const { data } = this.getSortedData();
    return (
      <React.Fragment>
        <div className="table-responsive">
          <table className="table table-hover">
            <TableHeaderLz
              columns={this.columns}
              sortColumn={this.state.sortColumn}
              onSort={this.handleSort}
            />
            <TableBodyLz data={data} columns={this.columns} />
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default TableLz;

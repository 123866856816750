import React from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { Modal } from "reactstrap";
import LzForm from "./lzForm";
import { updatePass } from "../../services/userService";

class PassModal extends LzForm {
  state = {
    data: { oldPassword: "", newPassword: "", newPasswordC: "" },
    errors: {},
  };

  schema = {
    oldPassword: Joi.string().required().label("Password Anterior"),
    newPassword: Joi.string().required().label("Password Nuevo"),
    newPasswordC: Joi.string().required().label("Pasword Confirmado"),
  };

  handleToggle = () => {
    this.props.onToggle();
  };

  doSubmit = async () => {
    try {
      const { oldPassword, newPassword, newPasswordC } = this.state.data;
      if (newPassword === newPasswordC) {
        const body = {
          oldP: oldPassword,
          newP: newPassword,
          id: this.props.user.id,
        };
        const result = await updatePass(body);
        console.log(result);
        if (result.status === 200) {
          Swal.fire(
            "¡Contraseña Guardada!",
            "¡Inicia sesión para observar los cambios!",
            "success"
          );
          const data = { oldPassword: "", newPassword: "", newPasswordC: "" };
          this.setState({ data });
          this.props.onToggle();
        }
      } else {
        Swal.fire("¡Ups!", "¡Confirma la contraseña correctamente!", "error");
      }
    } catch (err) {
      Swal.fire("¡Ups!", "¡Contraseña Incorrecta!", "error");
    }
  };

  render() {
    const { modal } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={modal} toggle={this.handleToggle} className="modal-sm">
          <div className="modal-body p-1">
            <div className="row p-1">
              <div className="col-12 text-center">
                <h5 style={{ margin: "5px 0" }}>
                  <b>Introduce los valores solicitados</b>
                </h5>
              </div>
              <div className="col mt-2 text-center">
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "oldPassword",
                    "Contraseña Actual",
                    "password",
                    "Contraseña Actual"
                  )}
                  {this.renderInput(
                    "newPassword",
                    "Contraseña Nueva",
                    "password",
                    "Contraseña Nueva"
                  )}
                  {this.renderInput(
                    "newPasswordC",
                    "Confirma tu contraseña nueva",
                    "password",
                    "Confirmación"
                  )}
                  <button className="btn btn-round btn-danger" type="submit">
                    Cambiar
                  </button>
                </form>
              </div>
            </div>
            <div className="row justify-content-center mt-1"></div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PassModal;

import React from "react";
import Swal from "sweetalert2";
import { PayPalButton } from "react-paypal-button-v2";
import { activePropiedad } from "../../services/propiedadesService";
import { payPalMail } from "../../services/checkOutService";

const PayPalSubscription = ({ propertyId, onModal, onUpdate }) => {
  const options = {
    clientId: process.env.REACT_APP_PAYPAL,
    currency: "MXN",
    vault: true,
    disableFunding: "card",
  };

  const style = {
    label: "pay",
    size: "medium",
    shape: "pill",
    color: "gold",
  };

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: process.env.REACT_APP_PAYPAL_SUBS,
    });
  };

  const onApprove = (data, actions) => {
    return actions.subscription
      .get()
      .then(async (response) => {
        const body = {
          isActive: true,
          isAvailable: true,
          subCode: response.id,
          subType: "PP",
        };
        onModal();
        const result = await activePropiedad(propertyId, body);
        if (result.status === 200) {
          const body = { template: "payments", title: "Propiedad Publicada" };
          await payPalMail(body);
          onUpdate();
          Swal.fire({
            title: "¡Listo!",
            text: "Tu propiedad se encuentra pública",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "¡Ups!",
          text:
            "Ocurrio un error, manda un correo a info@capitolcity2.com para ayudarte",
          icon: "error",
        });
      });
  };

  const onError = (error) => {
    console.log(error);
  };

  const onCancel = (data, actions) => {
    console.log("Proceso cancelado");
  };

  return (
    <PayPalButton
      options={options}
      style={style}
      createSubscription={(data, actions) => createSubscription(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onCancel={(data, actions) => onCancel(data, actions)}
      onError={(error) => onError(error)}
    />
  );
};

export default PayPalSubscription;

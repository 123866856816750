import React from "react";
import wNumb from "wnumb";

const PropertyDetailsHeader = (props) => {
  const {
    propiedad,
    tipo,
    estado,
    ciudad,
    colonia,
    calle,
    metros,
    precio,
    usuario_id,
    agua,
    drenaje,
    luz,
    isActiveRegistro,
    id,
    isActive,
  } = props.propiedad;

  const isFavorito = props.isFavorito;
  const userId = props.usuario;
  const precioMetro = parseInt(precio / metros);
  const fecha1 = new Date(isActiveRegistro);
  const fecha2 = new Date();
  const diffTime = Math.abs(fecha2 - fecha1);
  const diffMin = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const handleFavorito = () => {
    props.favorito(isFavorito);
  };

  const moneyFormat = wNumb({
    mark: ".",
    thousand: ",",
    prefix: "$ ",
  });

  return (
    <React.Fragment>
      {" "}
      <div className="col-md-3 align-self-center">
        <div className="col">
          <h5>
            <i className="fa fa-handshake-o" aria-hidden="true" />{" "}
            &nbsp;&nbsp;&nbsp;
            <b>
              {propiedad} en {tipo}
            </b>
          </h5>
          {userId !== usuario_id && (
            <React.Fragment>
              <a
                href="#favorito"
                onClick={handleFavorito}
                className="align-self-center fa-lg  ml-2"
                style={{ color: "black" }}
              >
                <i
                  className={isFavorito ? "fa fa-heart" : "fa fa-heart-o"}
                  aria-hidden="true"
                  style={{ color: isFavorito ? "rgba(192,0,0,1)" : "black" }}
                />
                &nbsp;&nbsp;
                <span>
                  <b>
                    {" "}
                    {isFavorito
                      ? "Propiedad Favorita"
                      : "Agregar a Favoritos"}{" "}
                  </b>
                </span>
              </a>
            </React.Fragment>
          )}
          <div className="col pt-3">
            <h6>
              {isActive ? (
                <b>
                  *Publicado hace:{" "}
                  {diffMin >= 60
                    ? diffHours >= 24
                      ? diffDays + (diffDays === 1 ? " día" : " días")
                      : diffHours + (diffHours === 1 ? " hora" : " horas")
                    : diffMin + (diffMin === 1 ? " minuto" : " minutos")}
                </b>
              ) : null}
            </h6>
          </div>
        </div>
      </div>
      <div className="col-md-3 align-self-center">
        <div className="col">
          <h5>
            <i className="fa fa-globe" aria-hidden="true"></i>
            &nbsp;
            <b>
              Ubicación: {ciudad}, {estado}
            </b>
          </h5>
        </div>
        <div className="col">
          <h5>
            <i className="fa fa-id-card" aria-hidden="true"></i>
            &nbsp;
            <b>Propiedad: {"#2020" + id}</b>
          </h5>
        </div>
      </div>
      <div className="col-md-3 align-self-center">
        <div className="col">
          <h5>
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            &nbsp;
            <b>Colonia: {colonia}</b>
          </h5>
        </div>
        <div className="col">
          <h5>
            <i className="fa fa-road" aria-hidden="true"></i>&nbsp;
            <b>Calle: {calle}</b>
          </h5>
        </div>
        <div className="col">
          <h5>
            <i className="fa fa-file-text" aria-hidden="true" />
            &nbsp;
            <b>
              {" "}
              Servicios: Agua
              <i
                className={agua ? "fa fa-check" : "fa fa-times"}
                aria-hidden="true"
                style={{ color: agua ? "#32a852" : "#f7765f" }}
              />{" "}
              Drenaje
              <i
                className={drenaje ? "fa fa-check" : "fa fa-times"}
                aria-hidden="true"
                style={{ color: drenaje ? "#32a852" : "#f7765f" }}
              />{" "}
              Luz{" "}
              <i
                className={luz ? "fa fa-check" : "fa fa-times"}
                aria-hidden="true"
                style={{ color: luz ? "#32a852" : "#f7765f" }}
              />{" "}
            </b>
          </h5>
        </div>
      </div>
      <div className="col-md-3 align-self-center">
        <div className="col">
          <h5>
            <i className="fa fa-arrows-h" aria-hidden="true" />
            &nbsp;
            <b>Mts²: {metros}</b>
          </h5>
        </div>
        <div className="col">
          <h5>
            <i className="fa fa-money" aria-hidden="true" /> &nbsp;
            <b>Precio: {moneyFormat.to(precio)}</b>
          </h5>
        </div>
        <div className="col">
          <h5>
            <i className="fa fa-usd" aria-hidden="true" /> &nbsp;
            <b>m²: {moneyFormat.to(precioMetro)}</b>
          </h5>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyDetailsHeader;

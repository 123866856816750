import http from "./httpService";

export function getPoints(userId){
  return http.get("/premium/" + userId);
}

export function reqPremium(body) {
  return http.post("/premium", body);
}

export function updatePayPal(body) {
  return http.post("/premium/paypal", body);
}

import http from "./httpService";

export function postFavorito(body) {
  return http.post("/favorito", body);
}

export function getFavorito(usuarioId) {
  return http.get("/favorito/" + usuarioId);
}

export function checkFavorito(propiedadId, usuarioId) {
  return http.get("/favorito/" + propiedadId + "/" + usuarioId);
}

export function delFavorito(propiedadId, usuarioId) {
  return http.delete("/favorito/" + propiedadId + "/" + usuarioId);
}

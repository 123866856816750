import React, { Component } from "react";
import Nouislider from "nouislider-react";
import _ from "lodash";
//import auth from "../../services/authService";
import { getCiudades } from "../../services/namesMexico";
import { getPropiedadesEst } from "../../services/filterService";
import { getAddress } from "./mapContainer";

class SearchBar extends Component {
  state = {
    data: {
      estado: "",
      ciudad: "",
      tipo: "",
      precioMin: 0,
      precioMax: 9,
      metrosMin: 0,
      metrosMax: 3000,
      orden: "",
    },
    ciudades: [],
    propiedades: [],
  };

  callCiudades = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.value) {
      const { data: ciudades } = await getCiudades(input.value);
      this.setState({ data, ciudades });
      await getAddress(input.value);
      const { data: propiedades } = await getPropiedadesEst(
        input.value,
        this.props.user.id
      );
      for (let i = 0; i < propiedades.length; i++) {
        propiedades[i].fecha = Date.parse(propiedades[i].registro);
      }
      this.setState({ propiedades });
      data["ciudad"] = "";
      this.filterPropiedades(data);
    } else {
      this.setState({ data, ciudades: [], propiedades: [] });
    }
  };

  updateSearch = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.name === "tipo") {
      if (input.value === "Renta") {
        data["precioMax"] = 200;
      } else {
        data["precioMax"] = 9;
      }
    }

    this.setState({ data });
    if (input.name === "ciudad" && input.value) {
      const direccion =
        "Ciudad: " + input.value + ", Estado:" + this.state.estado;
      await getAddress(direccion);
    }

    this.filterPropiedades(data);
  };

  filterPropiedades = (data) => {
    const propiedades = { ...this.state.propiedades };
    const order = data.orden;
    const params = _.pickBy(
      _.pick(data, ["estado", "ciudad", "tipo"]),
      (v) => v
    );
    let filter = params ? _.filter(propiedades, params) : propiedades;
    filter =
      data.precioMax > 0
        ? _.filter(
            filter,
            this.predicate(
              data.precioMin * (params.tipo === "Renta" ? 1000 : 1000000),
              data.precioMax * (params.tipo === "Renta" ? 1000 : 1000000) + 1,
              "precio"
            )
          )
        : filter;
    filter =
      data.metrosMax > 0
        ? _.filter(
            filter,
            this.predicate(data.metrosMin, data.metrosMax + 1, "metros")
          )
        : filter;
    const orderArray = order ? order.split("_") : ["fecha", "desc"];
    filter = _.orderBy(filter, orderArray[0], orderArray[1]);
    filter = data.precioMax === 0 || data.metrosMax === 0 ? [] : filter;
    this.props.onUpdate(filter);
    return;
  };

  predicate = (start, end, name) => {
    return (propiedad) => {
      return _.inRange(propiedad[name], start, end);
    };
  };

  onSlide = (value) => {
    const data = { ...this.state.data };
    data["precioMin"] = value[0];
    data["precioMax"] = value[1];
    this.setState({ data });
    this.filterPropiedades(data);
  };

  onSlideMts = (value) => {
    const data = { ...this.state.data };
    data["metrosMin"] = value[0];
    data["metrosMax"] = value[1];
    this.setState({ data });
    this.filterPropiedades(data);
  };

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <div className="col-md-6">
          <label>
            <b>Estado</b>
          </label>
          <select
            onChange={this.callCiudades}
            name={"estado"}
            value={data["estado"]}
            className="form-control"
          >
            <option value=""></option>
            {this.props.estados.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <label>
            <b>Ciudad</b>
          </label>
          <select
            onChange={this.updateSearch}
            name={"ciudad"}
            value={data["ciudad"]}
            className="form-control"
          >
            <option value=""></option>
            {this.state.ciudades.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mt-2">
          <label>
            <b>Tipo</b>
          </label>
          <select
            onChange={this.updateSearch}
            name={"tipo"}
            value={data["tipo"]}
            className="form-control"
          >
            <option value=""></option>
            <option value="Venta">Venta</option>
            <option value="Renta">Renta</option>
          </select>
        </div>
        <div className="col-md-6 mt-2">
          <label>
            <b>Orden</b>
          </label>
          <select
            onChange={this.updateSearch}
            name={"orden"}
            value={data["orden"]}
            className="form-control"
          >
            <option value=""></option>
            <option value="fecha_desc">Más Nuevos</option>
            <option value="fecha_asc">Más Viejos</option>
            <option value="metros_desc">Más Grandes</option>
            <option value="metros_asc">Más Pequeños</option>
            <option value="precio_desc">Más Caros</option>
            <option value="precio_asc">Más Baratos</option>
          </select>
        </div>
        <div className="col-md-5 my-2">
          <label>
            <b>
              De: $
              {this.state.data.precioMin + (data.tipo === "Renta" ? "K" : "MM")}{" "}
              a $
              {this.state.data.precioMax + (data.tipo === "Renta" ? "K" : "MM")}
            </b>
          </label>
          <Nouislider
            range={
              data.tipo === "Renta" ? { min: 0, max: 200 } : { min: 0, max: 9 }
            }
            step={data.tipo === "Renta" ? 10 : 0.1}
            start={data.tipo === "Renta" ? [0, 200] : [0, 9]}
            connect
            onSlide={this.onSlide}
            style={{ margin: "0" }}
          />
        </div>
        <div className="col-md-5 my-2">
          <label>
            <b>
              De: {parseInt(this.state.data.metrosMin)}Mts² a&nbsp;
              {parseInt(this.state.data.metrosMax)}Mts²
            </b>
          </label>
          <Nouislider
            range={{ min: 0, max: 3000 }}
            step={10}
            start={[0, 3000]}
            connect
            onSlide={this.onSlideMts}
            style={{ margin: "0" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default SearchBar;

import React, { Component } from "react";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import auth from "../services/authService";
import IndexNavbar from "./indexNavbar";
import LzPagination from "./common/lzPagination";
import LzCard from "./common/lzCard";
import ProfileCol from "./common/profileCol";
import InboxList from "./common/inboxList";
import RegisterForm from "./common/registerForm";
import TableLz from "./common/tableLz";
import ProfileFilter from "./common/profileFilter";
import PassModal from "./common/passModal";
import { LoadingComponent } from "./common/loadingComponent";
import { getContacto } from "../services/contactoService";
import { sendEmail } from "../services/emailService";
import { getFavorito } from "../services/favoritoService";
import { getInbox } from "../services/inboxService";
import { getCartera } from "../services/carteraService";
import { getUserInfo } from "../services/userService";
import { paginate } from "./../utils/paginate";

class ProfileUser extends Component {
  state = {
    data: {
      usuario: "",
      nombre: "",
      apellido: "",
      telefono: "",
      password: "",
      propsact: 0,
      propsreg: 0,
    },
    inbox: [],
    propiedades: [],
    favoritos: [],
    currentPage: 1,
    currentPageInbox: 1,
    pageSize: 2,
    pageSizeInbox: 3,
    modificarDatos: false,
    cartera: false,
    contacto: true,
    carteraInfo: [],
    carteraFilter: [],
    modal: false,
  };

  async componentDidMount() {
    const { id: userId } = auth.getCurrentUser();
    const { data: favoritos } = await trackPromise(getFavorito(userId));
    const { data: propiedades } = await getContacto(userId);
    const { data: inbox } = await getInbox(userId);
    const { data: carteraInfo } = await getCartera(userId);
    const { data } = await getUserInfo(userId);
    data[0]["password"] = "xxxx";
    this.setState({
      favoritos,
      propiedades,
      inbox: inbox[0],
      carteraInfo: carteraInfo[0],
      carteraFilter: carteraInfo[0],
      data: data[0],
    });
  }

  handleContact = async (body, accion) => {
    const result = await trackPromise(sendEmail(body));
    if (result.status === 200) {
      Swal.fire(
        accion === "contactar" ? "¡Contactado!" : "¡Rechazado!",
        accion === "contactar"
          ? "¡Espera la llamada o correo de tu cliente!"
          : "Enviamos el mensaje al usuario",
        "success"
      );
      const { id: userId } = auth.getCurrentUser();
      const { data: inbox } = await getInbox(userId);
      this.setState({ inbox: inbox[0] });
    }
  };

  handleCartera = () => {
    const cartera = this.state.cartera;
    this.setState({ cartera: !cartera });
    if (cartera) {
      const carteraFilter = this.state.carteraInfo;
      this.setState({ carteraFilter });
    }
  };

  handleShowContacto = () => {
    const contacto = !this.state.contacto;
    this.setState({ contacto });
  };

  hanldeUpdate = (user) => {
    const modificar = this.state.modificarDatos;
    if (user) {
      this.setState({ modificarDatos: !modificar, data: user });
    } else {
      this.setState({ modificarDatos: !modificar });
      if (this.state.cartera) {
        this.setState({ cartera: false });
      }
    }
  };

  handleFilter = (filter) => {
    this.setState({ carteraFilter: filter });
  };

  handleModal = () => {
    const modal = this.state.modal;
    this.setState({ modal: !modal });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageChangeInbox = (page) => {
    this.setState({ currentPageInbox: page });
  };

  getPagedData = () => {
    const {
      currentPage,
      pageSize,
      currentPageInbox,
      pageSizeInbox,
      favoritos,
      inbox,
      propiedades,
    } = this.state;
    const resFav = paginate(favoritos, currentPage, pageSize);
    const resProps = paginate(propiedades, currentPage, pageSize);
    const resInbox = paginate(inbox, currentPageInbox, pageSizeInbox);
    return {
      totalCountFav: favoritos.length,
      totalCountProps: propiedades.length,
      totalCountInbox: inbox.length,
      resInbox,
      resFav,
      resProps,
    };
  };

  render() {
    const {
      totalCountFav,
      totalCountInbox,
      totalCountProps,
      resInbox,
      resFav,
      resProps,
    } = this.getPagedData();
    const {
      pageSize,
      pageSizeInbox,
      currentPage,
      currentPageInbox,
      data,
      modificarDatos,
      cartera,
      carteraFilter,
      carteraInfo,
      modal,
      contacto,
    } = this.state;

    return (
      <React.Fragment>
        <IndexNavbar clase="sticky-top" user={this.props.user} />
        <PassModal
          modal={modal}
          onToggle={this.handleModal}
          user={this.props.user}
        />
        <LoadingComponent />
        <div
          className=""
          style={{
            paddingRight: "5px",
            paddingLeft: "5px",
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px",
          }}
        >
          <div className="row">
            <div className="col-md-3">
              <ProfileCol
                data={data}
                modificar={modificarDatos}
                onUpdate={this.hanldeUpdate}
                onCartera={this.handleCartera}
                cartera={cartera}
                contacto={contacto}
                onContacto={this.handleShowContacto}
                modal={this.handleModal}
              />
            </div>
            {cartera && (
              <React.Fragment>
                <div className="col-md-9">
                  <div className="row">
                    <h3 className="col text-center">
                      <b>Cartera de clientes</b>
                    </h3>
                  </div>
                  <div className="row mt-2 justify-content-center">
                    <ProfileFilter
                      carteraInfo={carteraInfo}
                      contacto={contacto}
                      onFilter={this.handleFilter}
                    />
                  </div>
                  <div className="mt-2">
                    <TableLz cartera={carteraFilter} />
                  </div>
                </div>
              </React.Fragment>
            )}
            {!cartera && (
              <React.Fragment>
                <div className="col-md-4">
                  {modificarDatos && (
                    <RegisterForm
                      updateData={1}
                      data={data}
                      onUpdate={this.hanldeUpdate}
                    />
                  )}
                  {!modificarDatos && (
                    <React.Fragment>
                      <div className="row">
                        <h3 className="col text-center">
                          <b>Mensajes</b>
                        </h3>
                      </div>
                      <div className="row mt-2">
                        <div className="col">
                          {resInbox.length ? (
                            <React.Fragment>
                              <div className="list-group">
                                {resInbox.map((item) => (
                                  <InboxList
                                    key={item.id}
                                    data={item}
                                    onContact={this.handleContact}
                                  />
                                ))}
                              </div>{" "}
                            </React.Fragment>
                          ) : (
                            <h3 className="text-center">No tienes mensajes</h3>
                          )}
                          <div className="row mt-2 justify-content-center">
                            <LzPagination
                              itemsCount={totalCountInbox}
                              pageSize={pageSizeInbox}
                              currentPage={currentPageInbox}
                              onPageChange={this.handlePageChangeInbox}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="col-md-5">
                  <div className="row">
                    <h3 className="col text-center">
                      <b>
                        {"Propiedades " +
                          (+contacto ? "Contactadas" : "Favoritas")}
                      </b>
                    </h3>
                  </div>
                  <div
                    className="row justify-content-center"
                    style={{ fontSize: "12px" }}
                  >
                    <span>
                      <i className="fa fa-globe" aria-hidden="true" />
                      <b>Estado</b>
                    </span>
                    <span className="margin-icon">
                      <i className="fa fa-location-arrow" aria-hidden="true" />
                      <b>Ciudad</b>
                    </span>
                    <span className="margin-icon">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      <b>Colonia</b>
                    </span>
                    <span className="margin-icon">
                      <i className="fa fa-road" aria-hidden="true" />
                      <b>Calle</b>
                    </span>
                    <span className="margin-icon">
                      <i className="fa fa-file-text" aria-hidden="true" />
                      <b>Servicios</b>
                    </span>
                    <span className="margin-icon">
                      <i className="fa fa-arrows-h" aria-hidden="true" />
                      <b>Mts²</b>
                    </span>
                    <span className="margin-icon">
                      <i className="fa fa-money" aria-hidden="true" />
                      <b>Precio</b>
                    </span>
                  </div>
                  {contacto ? (
                    <div className="row mt-2 justify-content-center">
                      {resProps.length ? (
                        resProps.map((item) => (
                          <LzCard
                            key={item.id}
                            data={item}
                            onReview={this.handleReview}
                            flag={true}
                          />
                        ))
                      ) : (
                        <h3>No tienes contactados</h3>
                      )}
                    </div>
                  ) : (
                    <div className="row mt-2 justify-content-center">
                      {resFav.length ? (
                        resFav.map((item) => (
                          <LzCard
                            key={item.id}
                            data={item}
                            onReview={this.handleReview}
                            flag={true}
                          />
                        ))
                      ) : (
                        <h3>No tienes favoritos</h3>
                      )}
                    </div>
                  )}
                  <div className="row mt-2 justify-content-center">
                    <LzPagination
                      itemsCount={contacto ? totalCountProps : totalCountFav}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileUser;

/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
//<video src={video} autoPlay loop playsInline muted></video>
import React, { Component } from "react";
//import ReactPlayer from "react-player/lazy";
import PremiumModal from "./common/premiumModal";
import InfoModal from "./common/infoModal";
import logo from "../assets/img/capitol_city_3.png";
import comodidad from "../assets/img/comodidad.png";
import alcance from "../assets/img/alcance.png";
import estadisticas from "../assets/img/estadisticas.png";
import control from "../assets/img/control_propiedades.png";
import analisis from "../assets/img/analisis_mercado.png";
//import comparacion from "../assets/img/comparacion_mercado.png";
//import video from "../assets/video/video_hd.mp4";
//import inicio from "../assets/img/saltillo_4.png";
// core components

class IndexHeader extends Component {
  state = {
    modal: false,
    modalInfo: false,
  };

  handleModal = () => {
    if (this.props.user) {
      const modal = !this.state.modal;
      this.setState({ modal });
    } else {
      window.location = "/credentials";
    }
  };

  handleModalInfo = () => {
    const modalInfo = !this.state.modalInfo;
    this.setState({ modalInfo });
  };

  render() {
    return (
      <React.Fragment>
        <InfoModal
          modal={this.state.modalInfo}
          onToggle={this.handleModalInfo}
          infoType={"coti"}
        />
        <PremiumModal modal={this.state.modal} onToggle={this.handleModal} />
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/saltillo_4.png") + ")",
          }}
        >
          <div className="container content-center">
            {this.props.user && (
              <h1 className="presentation-subtitle text-center mb-2"></h1>
            )}
            <div className="title-brand">
              {!this.props.user && (
                <img src={logo} alt="capitol-logo" className="capitol-logo" />
              )}
            </div>
            <h1
              className="presentation-subtitle text-center"
              style={{ fontSize: !this.props.user ? "1.7em" : "50px" }}
            >
              {!this.props.user
                ? "ANÁLISIS DEMOGRÁFICO DIGITAL"
                : "BIENVENIDO " + this.props.user.nombre.toUpperCase()}
            </h1>
            {!this.props.user && (
              <React.Fragment>
                <div className="row justify-content-center mt-3">
                  <a
                    className="btn btn-primary ml-2 btn-lg btn-round"
                    href="/credentials/1"
                    style={{ width: "250px" }}
                  >
                    Registrarme Gratis
                  </a>
                  <a
                    className="btn btn-danger ml-2 btn-lg btn-round blink-2"
                    href="/playground"
                    style={{ width: "200px" }}
                  >
                    Demo Gratis
                  </a>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {!this.props.user ? (
          <React.Fragment>
            <div className="row justify-content-center px-1 py-1 pt-md-3">
              <h1 className="display-4" style={{ zIndex: "2", color: "black" }}>
                ¿Por qué Capitol City?
              </h1>
            </div>
            <div className="row pt-3">
              <div className="col text-center">
                <span style={{ fontSize: "20px" }}>
                  <b>
                    Tenemos la mejor herramienta en México de análisis de
                    mercado.
                  </b>
                </span>
              </div>
            </div>
            <div className="row mt-1 pt-1 mb-4 pb-4">
              <div className="col-md-6 land-display">
                <div className="row pb-3">
                  <div className="col text-center">
                    <span className="sub-Index u-land">Land</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 pt-3 text-center">
                    <img
                      src={control}
                      className="rounded-circle img-index img-l"
                    ></img>
                    <h4 className="heading-capitol">Control de Propiedades</h4>
                    <p>
                      <b>Administra todas tus propiedades</b>
                    </p>
                  </div>
                  <div className="col-md-4 pt-3 text-center">
                    <img
                      src={comodidad}
                      className="rounded-circle img-index img-l"
                    ></img>
                    <h4 className="heading-capitol">Comodidad</h4>
                    <p>
                      <b>Busca ó sube propiedades de cualquier lugar</b>
                    </p>
                  </div>
                  <div className="col-md-4 pt-3 text-center">
                    <img
                      src={alcance}
                      className="rounded-circle img-index img-l"
                    ></img>
                    <h4 className="heading-capitol">Alcance</h4>
                    <p>
                      <b>Tu mercado son todos los usuarios</b>
                    </p>
                  </div>
                </div>
              </div>
              {
                // *************Termina la parte de Land y empieza Analytics************
              }
              <div className="col-md-12">
                {/*<div className="row pb-3">
                  <div className="col text-center">
                    <span className="sub-Index u-an">Analytics</span>
                  </div>
            </div>*/}
                <div className="row">
                  <div className="col-md-4 pt-3 text-center">
                    <img
                      src={alcance}
                      className="rounded-circle img-index img-a"
                    ></img>
                    <h4 className="heading-capitol">
                      Análisis demografico digital
                    </h4>
                    {/*<p>
                      <b>Análisis demografico digital</b>
                    </p>*/}
                  </div>
                  <div className="col-md-4 pt-3 text-center">
                    <img
                      src={estadisticas}
                      className="rounded-circle img-index img-a"
                    ></img>
                    <h4 className="heading-capitol">
                      Análisis de flujo de vehicular
                    </h4>
                    {/*<p>
                      <b>Análisis de flujo de vehiculos digital</b>
                    </p>*/}
                  </div>
                  <div className="col-md-4 pt-3 text-center">
                    <img
                      src={analisis}
                      className="rounded-circle img-index img-a"
                    ></img>
                    <h4 className="heading-capitol">Análisis de Competencia</h4>
                    {/*<p>
                      <b>Análisis de Competencia</b>
                    </p>*/}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div style={{ backgroundColor: "rgba(242,242,242,1)" }}>
          <div className="row justify-content-center px-1  pb-md-3">
            <a
              href="https://www.youtube.com/channel/UC0OQQl1pXWHLAy3ICfp3BVg"
              target="_blank"
            >
              <h1 className="display-4 text-danger" style={{ zIndex: "2" }}>
                Conócenos <i className="fa fa-youtube" aria-hidden="true"></i>
              </h1>
            </a>
          </div>
          {/*<div className="row justify-content-center mt-3">
            <div className="col-md-3 pb-4" style={{ borderRadius: "20px" }}>
              <ReactPlayer
                className="react-player"
                url={"https://www.youtube.com/watch?v=x_35I90T-MU&t=19s"}
                controls={true}
                width={"100%"}
                height={"250px"}
              />
            </div>
            <div className="col-md-3 pb-4" style={{ borderRadius: "20px" }}>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=_yxyHxAkZE4"
                controls={true}
                width={"100%"}
                height={"250px"}
              />
            </div>
            <div className="col-md-3 pb-4" style={{ borderRadius: "20px" }}>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=Y3cgjZS-5As"
                controls={true}
                width={"100%"}
                height={"250px"}
              />
            </div>
            <div className="col-md-3 pb-4" style={{ borderRadius: "20px" }}>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=na_xICvJgFE"
                controls={true}
                width={"100%"}
                height={"250px"}
              />
            </div>
        </div>*/}
        </div>
        <div
          className={
            "row justify-content-center px-1 py-1 " +
            (this.props.user ? "" : "mt-3")
          }
        >
          <div className="pb-2" style={{ width: "100%" }}>
            <h1
              className="display-4 text-center"
              style={{ zIndex: "2", color: "black" }}
            >
              Elige tu servicio
            </h1>
          </div>
          <div className="container mt-3">
            <div className="card-deck mb-3 text-center">
              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    Análisis Demográfico
                  </h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <div className="ribbon-card ribbon-new">
                    <span>NUEVO</span>
                  </div>
                  <h1
                    className="card-title pricing-card-title"
                    style={{ fontWeight: "bold", padding: "20px 0" }}
                  >
                    $999
                    <small
                      className="text-muted"
                      style={{ fontWeight: "bold" }}
                    >
                      {" "}
                      MXN
                    </small>
                  </h1>
                  <ul className="mb-4 text-left" style={{ fontWeight: "bold" }}>
                    <li>Análisis de flujo vehicular.</li>
                    <li>Nivel socioeconomico.</li>
                    <li>Cantidad de viviendas y ocupantes promedio.</li>
                    <li>Indicadores clave de la zona.</li>
                    <li>Densidad poblacional.</li>
                    <li>Resultados en menos de 24 hrs.</li>
                  </ul>
                  <button
                    className="btn btn-lg btn-block btn-danger mt-auto"
                    onClick={this.handleModal}
                  >
                    Solicitar
                  </button>
                  <small>
                    <b>Aplican Terminos y Condiciones</b>
                  </small>
                </div>
              </div>

              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Análisis Tráfico</h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <div className="ribbon-card ribbon-new">
                    <span>NUEVO</span>
                  </div>
                  <h1
                    className="card-title pricing-card-title"
                    style={{ fontWeight: "bold", padding: "20px 0" }}
                  >
                    $199
                    <small
                      className="text-muted"
                      style={{ fontWeight: "bold" }}
                    >
                      {" "}
                      MXN
                    </small>
                  </h1>
                  <ul className="mb-4 text-left" style={{ fontWeight: "bold" }}>
                    <li>Flujo vehicular por semana.</li>
                    <li>Velocidad promedio.</li>
                    <li>Nivel de congestión de vehiculos.</li>
                    <li>Clasificación de calles.</li>
                    <li>Disponibilidad en todo el mundo.</li>
                    <li>Resultados en menos de 24 hrs.</li>
                  </ul>
                  <a
                    className="btn btn-lg btn-block btn-danger mt-auto"
                    href="/trafico"
                  >
                    Solicitar
                  </a>
                  <small>
                    <b>Aplican Terminos y Condiciones</b>
                  </small>
                </div>
              </div>

              <div className="card mb-4 empty-card box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    Servicios Avanzados Profesionales
                  </h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <div className="ribbon-card ribbon-new">
                    <span>NUEVO</span>
                  </div>
                  <h4
                    className="card-title pricing-card-title"
                    style={{ fontWeight: "bold", padding: "20px 0" }}
                  >
                    Solicita tu cotización
                  </h4>
                  <ul className="mb-4 text-left" style={{ fontWeight: "bold" }}>
                    <li>Análisis de competencia.</li>
                    <li>Localiza los potencializadores de tu negocio.</li>
                    <li>Compara las ubicaciones de tus sucursales.</li>
                    <li>
                      Encuentra las mejores zonas para tu negocio en la ciudad.
                    </li>
                  </ul>
                  <button
                    className="btn btn-lg btn-block btn-danger mt-auto"
                    onClick={this.handleModalInfo}
                  >
                    Solicitar
                  </button>
                  <small>
                    <b>Aplican Terminos y Condiciones</b>
                  </small>
                </div>
              </div>
            </div>
            <div className="card-deck mb-3 text-center land-display">
              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    Administrar Propiedades
                  </h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <h1
                    className="card-title pricing-card-title"
                    style={{ fontWeight: "bold", padding: "20px 0" }}
                  >
                    Gratis
                  </h1>
                  <ul
                    className=" mb-4 text-left"
                    style={{ fontWeight: "bold" }}
                  >
                    <li>Agregar propiedades sin limite</li>
                    <li>Subir fotos y ubicación exacta de cada propiedad</li>
                    <li>Comparar tus propiedades con el mercado</li>
                  </ul>
                  <a
                    className="btn btn-lg btn-block btn-danger mt-auto"
                    href="/propiedades/1"
                  >
                    Agregar Propiedad
                  </a>
                  <small>
                    <b>Aplican Terminos y Condiciones</b>
                  </small>
                </div>
              </div>
              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">
                    Publicar Propiedades
                  </h4>
                </div>
                <div className="card-body d-flex flex-column">
                  {/*<p style={{ marginBottom: "1px", fontWeight: "bolder" }}>
                    <b>Primeros 2 meses gratis</b>
                    </p>*/}
                  <h1
                    className="card-title pricing-card-title"
                    style={{
                      fontWeight: "bold",
                      padding: "20px 0",
                    }}
                  >
                    Gratis
                  </h1>
                  <ul className="mb-4 text-left" style={{ fontWeight: "bold" }}>
                    <li>Publicación para todos los usuarios de Capitol City</li>
                    <li>
                      Contacto por correo, teléfono o en tu inbox de Capitol
                      City
                    </li>
                    <li>
                      Puedes tener propiedades privadas y públicas para
                      administrar tu portafolio
                    </li>
                  </ul>
                  <a
                    className="btn btn-lg btn-block btn-danger mt-auto"
                    href="/propiedades"
                  >
                    Publicar
                  </a>
                  <small>
                    <b>Aplican Terminos y Condiciones</b>
                  </small>
                </div>
              </div>

              <div className="card mb-4 empty-card box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Análisis Place It</h4>
                </div>
                <div className="card-body d-flex flex-column">
                  <div className="ribbon-card">
                    <span>PROXIMAMENTE</span>
                  </div>
                  <ul
                    className="mt-3 mb-4 text-left"
                    style={{ fontWeight: "bold", padding: "20px 0" }}
                  ></ul>
                  <button
                    className="btn btn-lg btn-block btn-secondary mt-auto"
                    disabled={true}
                  >
                    Solicitar
                  </button>
                  <small>
                    <b>Aplican Terminos y Condiciones</b>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IndexHeader;

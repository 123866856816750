import http from "./httpService";

export function getPropiedades(nombre, idUser) {
  return http.get("/propiedades/" + nombre + "/" + idUser);
}

export function getPropiedad(id) {
  return http.get("/propiedades/" + id);
}

export function activePropiedad(id, body) {
  return http.put("/propiedades/activeprop/" + id, body);
}

export function deletePropiedad(id) {
  return http.delete("/propiedades/" + id);
}

export function availablePropiedad(propiedadId, flag) {
  const body = { id: propiedadId, isActive: 0, isAvailable: flag };
  return http.put("/propiedades", body);
}

export function savePropiedad(propiedad) {
  propiedad.colonia =
    propiedad.colonia.charAt(0).toUpperCase() +
    propiedad.colonia.slice(1).toLowerCase();
  propiedad.calle =
    propiedad.calle.charAt(0).toUpperCase() +
    propiedad.calle.slice(1).toLowerCase();
  propiedad.colonia = propiedad.colonia.trim();
  propiedad.calle = propiedad.calle.trim();
  if (propiedad.id) {
    const body = { ...propiedad };
    delete body.id;
    delete body.usuario_id;
    delete body.registro;
    delete body.isAvailable;
    delete body.isActive;
    delete body.isActiveRegistro;
    return http.put("/propiedades/" + propiedad.id, body);
  }
  return http.post("/propiedades", propiedad);
}

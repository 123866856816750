/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

function FooterBD() {
  return (
    <footer
      className="footer"
      style={{
        fontSize: "1.3rem",
        backgroundColor: "rgb(0,17,28)",
        position: "relative",
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
      }}
    >
      <div style={{ margin: "0 auto", color: "white" }}>
        <div className="row">
          <div className="mt-3 col-md-10">
            <p>
              <b>
                © {new Date().getFullYear()}, Capitol City. All rights reserved.{" "}
                <a
                  href="https://fragoso09.github.io/capcitydocs.github.io/"
                  target="_blank"
                  style={{ marginLeft: "20px", paddingRight: "10px" }}
                >
                  Terminos y Condiciones
                </a>
                <a
                  href="https://fragoso09.github.io/capcitydocs.github.io/avisoPrivacidad.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: "10px",
                    paddingRight: "10px",
                    marginRight: "10px",
                  }}
                >
                  Aviso de Privacidad
                </a>
                Información: info@capitolcity2.com
              </b>
            </p>
          </div>
          <div className=" mt-2 pl-2 col-md-2 text-right">
            <a
              href="https://www.facebook.com/Capitol-City-100369398488883"
              target="_blank"
              className="mr-2"
              style={{ color: "#fff" }}
            >
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UC0OQQl1pXWHLAy3ICfp3BVg"
              target="_blank"
              className="mr-2"
              style={{ color: "#fff" }}
            >
              <i className="fa fa-youtube" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.instagram.com/capitolcity2/"
              target="_blank"
              className="mr-2"
              style={{ color: "#fff" }}
            >
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterBD;

import React from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { LoadingComponent } from "./loadingComponent";
import { Modal } from "reactstrap";
import LzForm from "./lzForm";
import { newContacto } from "../../services/contactoService";

class InfoModal extends LzForm {
  state = {
    data: { correo: "", desc: "" },
    errors: {},
  };

  schema = {
    correo: Joi.string().required().email().label("Correo"),
    desc: Joi.string().optional().label("Descripción"),
  };

  handleToggle = () => {
    this.props.onToggle();
  };

  doSubmit = async () => {
    try {
      const { correo, desc: comentario } = this.state.data;
      const tipo = this.props.infoType;
      const res = await trackPromise(newContacto({ correo, comentario, tipo }));
      if (res.status === 200) {
        Swal.fire(
          "¡Listo!",
          "Te contactaremos en menos de 24 horas hábiles",
          "success"
        );
        const data = { ...this.state.data };
        data["correo"] = "";
        data["desc"] = "";
        this.setState({ data });
        this.props.onToggle();
      }
    } catch (err) {
      Swal.fire("¡Ups!", "Ocurrio un error, intenta nuevamente", "error");
    }
  };

  render() {
    const { modal, infoType } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={modal} toggle={this.handleToggle} className="">
          <LoadingComponent />
          <div className="modal-body p-1">
            <div className="row p-1">
              <div className="col-12 text-center">
                <h5 style={{ margin: "5px 0" }}>
                  <b>
                    {infoType === "reg"
                      ? "Envía tus comentarios"
                      : "Solicita tu cotización"}
                  </b>
                </h5>
              </div>
              <div className="col mt-2 text-center">
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "correo",
                    "Ingresa tu correo de contacto",
                    "text",
                    "Ej. info@capitolcity2.com"
                  )}
                  {this.renderInput(
                    "desc",
                    infoType === "reg"
                      ? "Dudas o comentarios"
                      : "Nombre de la empresa",
                    "text",
                    infoType === "reg"
                      ? "Ej. Quiero conocer más sobre la plataforma"
                      : "Ej. Capitol City"
                  )}
                  <button className="btn btn-round btn-danger" type="submit">
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default InfoModal;

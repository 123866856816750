/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import logoBlue from "../assets/img/capitol_city_1.png";
import logoWhite from "../assets/img/capitol_city_3.png";
import InfoModal from "./common/infoModal";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [logoColor, setLogoColor] = React.useState("white");
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [dropdownOpenAn, setDropdownOpenAn] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleAn = () => setDropdownOpenAn(!dropdownOpenAn);
  const handleModal = () => setModal(!modal);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299 ||
        props.clase === "sticky-top"
      ) {
        setNavbarColor("");
        setLogoColor("blue");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
        setLogoColor("white");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    if (props.clase === "sticky-top") {
      setNavbarColor("");
    }

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, [props.clase]);

  return (
    <React.Fragment>
      <InfoModal modal={modal} onToggle={handleModal} infoType={"reg"} />
      <Navbar className={classnames(props.clase, navbarColor)} expand="lg">
        <Container>
          <div className={"navbar-translate"}>
            {props.user || props.flag || props.page === "/playground" ? (
              <NavbarBrand
                data-placement="bottom"
                href="/index"
                title="Capitol City"
              >
                <img
                  src={
                    logoColor === "white" && props.clase !== "sticky-top"
                      ? logoWhite
                      : logoBlue
                  }
                  alt="capitol-logo"
                  style={{ width: "175px", height: "33px" }}
                />
              </NavbarBrand>
            ) : (
              <NavbarBrand />
            )}
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/playground">
                  <span
                    className={props.page === "/playground" ? "loc-line" : ""}
                  >
                    Playground
                  </span>
                </NavLink>
              </NavItem>
              {props.user && (
                <React.Fragment>
                  {" "}
                  <Dropdown
                    nav
                    className={
                      dropdownOpen ? "dd-open land-display" : " land-display"
                    }
                    isOpen={dropdownOpen}
                    toggle={toggle}
                  >
                    <DropdownToggle nav caret>
                      <span
                        className={
                          props.page === "/propiedades" ||
                          props.page === "/maps"
                            ? "loc-line"
                            : ""
                        }
                      >
                        {" "}
                        Terrenos{" "}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      className={props.clase === "sticky-top" ? "dd-white" : ""}
                    >
                      <DropdownItem
                        href="/maps"
                        className={
                          props.page === "/maps"
                            ? "dd-active"
                            : props.clase === "sticky-top"
                            ? "dd-white"
                            : ""
                        }
                      >
                        Busqueda
                      </DropdownItem>
                      <DropdownItem
                        href="/propiedades"
                        className={
                          props.page === "/propiedades"
                            ? "dd-active"
                            : props.clase === "sticky-top"
                            ? "dd-white"
                            : ""
                        }
                      >
                        Tus Propiedades
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    nav
                    className={dropdownOpenAn ? "dd-open" : ""}
                    isOpen={dropdownOpenAn}
                    toggle={toggleAn}
                  >
                    <DropdownToggle nav caret>
                      <span
                        className={
                          props.page === "/premium" || props.page === "/trafico"
                            ? "loc-line"
                            : ""
                        }
                      >
                        {" "}
                        Análisis{" "}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      className={props.clase === "sticky-top" ? "dd-white" : ""}
                    >
                      <DropdownItem
                        href="/premium"
                        className={
                          props.page === "/premium"
                            ? "dd-active"
                            : props.clase === "sticky-top"
                            ? "dd-white"
                            : ""
                        }
                      >
                        Demográfico
                      </DropdownItem>
                      <DropdownItem
                        href="/trafico"
                        className={
                          props.page === "/trafico"
                            ? "dd-active"
                            : props.clase === "sticky-top"
                            ? "dd-white"
                            : ""
                        }
                      >
                        Tráfico
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </React.Fragment>
              )}
              <NavItem>
                <Button className="btn-round btn-gray" onClick={handleModal}>
                  Ayuda
                </Button>
              </NavItem>
              {!props.user && (
                <NavItem>
                  <Button
                    className="btn-round"
                    color="primary"
                    href="/credentials"
                  >
                    Iniciar Sesión
                  </Button>
                </NavItem>
              )}
              {props.user && (
                <React.Fragment>
                  <NavItem>
                    <Button
                      className="btn-round"
                      color="primary"
                      href="/profile"
                    >
                      {props.user.nombre}
                    </Button>
                  </NavItem>

                  <NavItem>
                    <NavLink href="/logout">
                      <p>Salir</p>
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}

export default IndexNavbar;

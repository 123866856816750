import React, { Component } from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import wNumb from "wnumb";
import { trackPromise } from "react-promise-tracker";
import { loadStripe } from "@stripe/stripe-js";
import { LoadingComponent } from "./common/loadingComponent";
import IndexNavbar from "./indexNavbar";
import LinearChart from "./common/linearChart";
import PaymentModal from "./common/paymentModal";
import {
  PremiumMap,
  trafficPins,
  deleteMarkers,
  markerPremium,
  drawPolyline,
  colorTrafficMarker,
  markerSucursal,
} from "./common/premiumMap";
import auth from "../services/authService";
import { checkOutProduct } from "../services/checkOutService";
import { reqTrafico, getRoads } from "../services/traficoService";
import { getSucursalesReg } from "../services/sucursalService";

class Trafico extends Component {
  state = {
    loadinfo: false,
    marker: 0,
    bothPins: 0,
    titulo: "",
    newPin: { titulo: "", lati: 0, lngi: 0, latf: 0, lngf: 0 },
    payment: false,
    info: [],
  };

  markerUpdate = (marker) => {
    this.setState({ marker });
  };

  reviewPins = (res) => {
    this.setState({ bothPins: res });
  };

  handleTitle = ({ currentTarget: input }) => {
    const newPin = { ...this.state.newPin };
    newPin["titulo"] = input.value;
    this.setState({ newPin });
  };

  loadInformation = async () => {
    this.setState({ loadinfo: true });
    const { id: userId } = auth.getCurrentUser();
    //Primero cargamos las sucursales
    const { data: sucursales } = await getSucursalesReg(userId);
    if (sucursales.length >= 1) {
      for (let i = 0; i < sucursales.length; i++) {
        const coords = { lat: sucursales[i].lat, lng: sucursales[i].lng };
        markerSucursal(coords, sucursales[i].titulo, 0);
      }
    }
    //Despues cargamos los tramos
    const { data: datos } = await getRoads(userId);
    if (datos.length >= 1) {
      let coordsA = {};
      let coordsB = {};
      let path = [];
      let status = 0;
      let info = [];

      for (let i = 0; i < datos.length; i++) {
        coordsA = {
          lat: datos[i].lati,
          lng: datos[i].lngi,
        };
        coordsB = {
          lat: datos[i].latf,
          lng: datos[i].lngf,
        };
        path = [coordsA, coordsB];
        status = datos[i].status;
        const { marker, mapas } = markerPremium(coordsA, status, 1, 0);
        marker.metadata = { id: i * 2, status: status };
        mapas.event.addListener(marker, "click", (e) => {
          this.markerUpdate(marker.metadata.id / 2);
          colorTrafficMarker(marker.metadata.id);
        });
        const { marker: markerB } = markerPremium(coordsB, status, 1, 1);
        markerB.metadata = { id: i * 2, status: status };
        mapas.event.addListener(markerB, "click", (e) => {
          this.markerUpdate(marker.metadata.id / 2);
          colorTrafficMarker(markerB.metadata.id);
        });
        await drawPolyline(path, status, true);
        if (i === 0) {
          colorTrafficMarker(0);
        }

        let trafico_sem = [];
        let con_sem = [];
        let con_fin = [];
        let total_flujo = 0;

        if (status === 2) {
          trafico_sem = datos[i].t_semana.split(",").map(Number);
          con_sem = datos[i].c_horas.split(",").map(Number);
          con_fin = datos[i].c_horas_fin.split(",").map(Number);

          for (let i = 0; i < trafico_sem.length; i++) {
            total_flujo += trafico_sem[i] * 14;
          }
        }

        info.push({
          titulo: datos[i].titulo,
          total_trafico: 0,
          velocidad: datos[i].velocidad,
          congestion: parseFloat(datos[i].congestion * 100).toFixed(2),
          calle: datos[i].calle,
          tipo: datos[i].tipo,
          t_semana: trafico_sem,
          c_horas: con_sem,
          c_horas_fin: con_fin,
          flujo_total: total_flujo,
        });
      }
      this.setState({ info });
    } else {
      Swal.fire(
        "Todavía no solicitas un tramo para análisis",
        "Solicítalo definiendo el tramo y en Calcular Flujo",
        "info"
      );
    }
    /*

*/
  };

  handleCalculate = async () => {
    const { aPoint, bPoint, res } = await trafficPins();
    const { titulo } = this.state.newPin;
    if (aPoint && bPoint) {
      if (res <= 1.5) {
        if (titulo) {
          const coords = [];
          coords.push({
            lat: aPoint.getPosition().lat(),
            lng: aPoint.getPosition().lng(),
          });
          coords.push({
            lat: bPoint.getPosition().lat(),
            lng: bPoint.getPosition().lng(),
          });
          await drawPolyline(coords, 1, false);
          Swal.fire({
            title: "¿Quieres solicitar el tramo seleccionado?",
            text: "Tiene un costo de $199 MXN",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.value) {
              const newPin = { ...this.state.newPin };
              newPin["lati"] = aPoint.getPosition().lat();
              newPin["lngi"] = aPoint.getPosition().lng();
              newPin["latf"] = bPoint.getPosition().lat();
              newPin["lngf"] = bPoint.getPosition().lng();
              newPin["titulo"] = titulo.toUpperCase();
              this.setState({ newPin, payment: true });
            }
          });
        } else {
          Swal.fire(
            "Titulo de tramo",
            "Captura el título para tu tramo",
            "warning"
          );
        }
      } else {
        Swal.fire(
          "Tramo muy largo",
          "Selecciona un tramo menor a 1.5 Km",
          "warning"
        );
      }
    } else {
      Swal.fire(
        "Solicitud Incompleta",
        "Coloca los dos pines para calcular el tramo",
        "error"
      );
    }
  };

  handleDelete = () => {
    deleteMarkers();
    const newPin = { ...this.state.newPin };
    newPin["titulo"] = "";
    this.setState({ newPin });
  };

  handleClean = () => {
    deleteMarkers();
    const newPin = { titulo: "", lati: 0, lngi: 0, latf: 0, lngf: 0 };
    this.setState({ newPin });
  };

  handlePaymentToggle = () => {
    this.setState({ payment: false });
  };

  handleCredit = async () => {
    try {
      this.handlePaymentToggle();
      const body = { ...this.state.newPin };
      const { id } = auth.getCurrentUser();
      body["usuario_id"] = id;
      const res = await trackPromise(reqTrafico(body));
      if (res.status === 200) {
        const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHED_KEY);
        //insertar proceso en base de datos y regresar el ID
        const body = {
          product: process.env.REACT_APP_STRIPE_PROD_TRAF,
          workId: res.data.insertId,
        };
        const result = await trackPromise(checkOutProduct(2, body));
        if (result.status === 200) {
          const stripe = await stripePromise;
          stripe.redirectToCheckout({ sessionId: result.data.id });
        }
      }
    } catch (err) {
      console.log(err);
      Swal.fire(
        "Error",
        "Ocurrio un error, contactanos en info@capitolcity2.com",
        "error"
      );
    }
  };

  handleHelp = () => {
    Swal.fire({
      title: "Detalles de métricas",
      html:
        "<ul style='text-align:justify'><li><p><b><b>Tráfico Semanal:</b> Cantidad de vehículos que pasan regularmente enfrente del punto por semana</b></p></li><li><p><b><b>Congestión:</b> Muestra el porcentaje de ocupación del tramo en diferentes horas durante el día, entre más alto mayor congestionamiento vial. Entre menor congestión, mayor flujo vehicular</b></p></li></ul></b></p></li></ul>",
      icon: "info",
    });
  };

  render() {
    const { loadinfo, bothPins, newPin, payment, info, marker } = this.state;
    const commaFormat = wNumb({
      thousand: ",",
    });
    const dataset = [
      {
        data: info.length
          ? JSON.parse(JSON.stringify(info[marker].t_semana))
          : [],
        borderColor: "rgba(255, 86, 0, 1)",
        pointBackgroundColor: "rgba(255, 86, 0, 1)",
        fill: false,
      },
    ];
    const dataset_con = [
      {
        label: "Entre Semana",
        data: info.length
          ? JSON.parse(JSON.stringify(info[marker].c_horas))
          : [],
        borderColor: "#12A5FF",
        pointBackgroundColor: "#12A5FF",
        fill: false,
      },
      {
        label: "Fin de Semana",
        data: info.length
          ? JSON.parse(JSON.stringify(info[marker].c_horas_fin))
          : [],
        borderColor: "rgba(0, 17, 28, 1)",
        pointBackgroundColor: "rgba(0, 17, 28, 1)",
        fill: false,
      },
    ];
    let minLine = info.length ? _.min(info[marker].t_semana) : 0;
    let maxLine = info.length ? _.max(info[marker].t_semana) : 0;
    let stepSize = info.length
      ? parseInt(
          (_.max(info[marker].t_semana) - _.min(info[marker].t_semana)) / 3
        )
      : 1;
    stepSize = Math.ceil(stepSize / 10) * 10;
    minLine = Math.floor(minLine / 10) * 10 - stepSize;
    maxLine = Math.ceil(maxLine / 10) * 10 + stepSize;
    minLine = minLine < 0 ? 0 : minLine;
    let maxArr = [];
    let minArr = [];
    if (info.length) {
      maxArr.push(_.max(info[marker].c_horas));
      maxArr.push(_.max(info[marker].c_horas_fin));
      minArr.push(_.min(info[marker].c_horas));
      minArr.push(_.min(info[marker].c_horas_fin));
    }
    let stepSizeCon = info.length ? (_.max(maxArr) - _.min(minArr)) / 3 : 1;
    let maxLineCon = _.max(maxArr) + stepSizeCon;
    let minLineCon =
      _.min(minArr) - stepSizeCon < 0 ? 0 : _.min(minArr) - stepSizeCon;

    return (
      <React.Fragment>
        <PaymentModal
          modal={payment}
          onToggle={this.handlePaymentToggle}
          onCredit={this.handleCredit}
          onPayment={this.handleClean}
          product={2}
          body={newPin}
        />
        <LoadingComponent />
        <IndexNavbar
          clase="sticky-top"
          user={this.props.user}
          page={this.props.match.path}
        />
        <div className="row mt-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col text-center">
                <span style={{ fontSize: "16px" }}>
                  <b>Resultados de Análisis</b>{" "}
                </span>
                <i
                  className="fa fa-info-circle ml-1 text-danger align-self-center"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={this.handleHelp}
                ></i>
              </div>
            </div>
            <div className="row py-1">
              <div className="col text-center">
                <span className="text-sblue" style={{ fontSize: "16px" }}>
                  <b>{info.length ? info[marker].titulo : ""}</b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col-md-4 text-center">
                <span>
                  <b>
                    Tráfico Semanal:{" "}
                    {info.length ? commaFormat.to(info[marker].flujo_total) : 0}
                  </b>
                </span>
              </div>
              <div className="col-md-4 text-center">
                <span>
                  <b>
                    Velocidad: {info.length ? info[marker].velocidad : 0} Km/hr
                  </b>
                </span>
              </div>
              <div className="col-md-4 text-center">
                <span>
                  <b>Cogestión: {info.length ? info[marker].congestion : 0}%</b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col-md-6 text-center">
                <span>
                  <b>Calle: {info.length ? info[marker].calle : "NA"}</b>
                </span>
              </div>
              <div className="col-md-6 text-center">
                <span>
                  <b>Tipo: {info.length ? info[marker].tipo : "NA"}</b>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <LinearChart
                    dataset={dataset}
                    labels={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
                    title={"Vehículos/Hora"}
                    stepSize={stepSize}
                    min={minLine}
                    max={maxLine}
                    legend={false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <LinearChart
                    dataset={dataset_con}
                    labels={[
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                      "13",
                      "14",
                      "15",
                      "16",
                      "17",
                      "18",
                      "19",
                      "20",
                      "21",
                    ]}
                    title={"Congestion/Horas"}
                    stepSize={stepSizeCon}
                    min={minLineCon}
                    max={maxLineCon}
                    legend={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {bothPins ? (
              <div className="row py-1">
                <div className="col-md-3">
                  <span className="align-self-center">
                    <b>Titulo del tramo:</b>
                  </span>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ej. Venustiano Carranza Nte-Sur"
                    style={{
                      height: "25px",
                    }}
                    onChange={this.handleTitle}
                    maxLength="50"
                    value={newPin.titulo}
                  />
                </div>
              </div>
            ) : null}
            <div className="row pb-1">
              <div className="col-md-4 ">
                {loadinfo ? (
                  <React.Fragment>
                    <span>
                      <b>
                        {" "}
                        <i
                          className="fa fa-thumb-tack"
                          aria-hidden="true"
                          style={{ color: "#E661AC" }}
                        />{" "}
                        Sucursal Activa&nbsp;&nbsp;
                      </b>
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      className={loadinfo ? "text-inactive" : "text-danger"}
                      onClick={loadinfo ? null : this.loadInformation}
                    >
                      <b>
                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                        Cargar Resultados&nbsp;&nbsp;
                      </b>
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className="col-md-4">
                <span
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                  onClick={this.handleCalculate}
                >
                  <b>
                    <i className="fa fa-bullseye" aria-hidden="true" />
                    Calcular Flujo
                  </b>
                </span>
              </div>
              <div className="col-md-4">
                <span
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                  onClick={this.handleDelete}
                >
                  <b>
                    <i className="fa fa-minus-circle" aria-hidden="true" />
                    Limpiar Pines
                  </b>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ">
                <span>
                  <b>
                    {" "}
                    <i
                      className="fa fa-map-marker"
                      aria-hidden="true"
                      style={{ color: "#6991FD" }}
                    />{" "}
                    Solicitud en Proceso&nbsp;&nbsp;
                  </b>
                </span>
              </div>
              <div className="col-md-4 ">
                <span>
                  <b>
                    <i
                      className="fa fa-map-marker"
                      aria-hidden="true"
                      style={{ color: "#00E64D" }}
                    />{" "}
                    Información Lista&nbsp;&nbsp;
                  </b>
                </span>
              </div>
              <div className="col-md-4 ">
                <span>
                  <b>
                    <i
                      className="fa fa-map-marker"
                      aria-hidden="true"
                      style={{ color: "#FC03C2" }}
                    />{" "}
                    Tramo a Calcular&nbsp;&nbsp;
                  </b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col text-center">
                <span className="text-sblue" style={{ fontSize: "13px" }}>
                  <b>
                    <i>
                      *Da clic derecho en el mapa para seleccionar el tramo de
                      inicio a fin que quieres analizar.
                    </i>
                  </b>
                </span>
              </div>
            </div>

            <div
              style={{
                height: "550px",
                paddingBottom: "5px",
              }}
            >
              {" "}
              <PremiumMap
                center={{ lat: 25.617847, lng: -100.287275 }}
                zoom={5}
                type={"road"}
                traffic={true}
                onPin={this.reviewPins}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Trafico;

import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import IndexNavbar from "./indexNavbar";
import PropertyDetailsHeader from "./common/propertyDetailsHeader";
import auth from "../services/authService";
import { LoadingComponent } from "./common/loadingComponent";
import {
  OrdinaryMap,
  drawPoly,
  delPoly,
  reDrawPoly,
} from "./common/ordinaryMap";
import { getPropiedad } from "../services/propiedadesService";
import { sendEmail } from "../services/emailService";
import { checkContacto } from "../services/contactoService";
import { postVista } from "../services/vistaService";
import {
  postFavorito,
  checkFavorito,
  delFavorito,
} from "../services/favoritoService";

class PropertyDetails extends Component {
  state = {
    data: [],
    contacto: false,
    isFavorito: false,
    analisisTiempo: false,
    showIso: false,
  };

  async componentDidMount() {
    const propertyid = this.props.match.params.id;
    const user = auth.getCurrentUser();
    const { data } = await trackPromise(getPropiedad(propertyid));
    if (!data[0].isActive || !data[0].isAvailable) {
      window.location = "/";
    }
    const { data: contactado } = await checkContacto(propertyid, user.id);
    const { data: favorito } = await checkFavorito(propertyid, user.id);
    data[0].fotos = data[0].fotos.split(",");
    const contacto = contactado.length ? true : false;
    const isFavorito = favorito.length ? true : false;
    this.setState({ data, contacto, isFavorito });
    if (user.id !== data[0].usuario_id) {
      await postVista({
        usuario_id: user.id,
        propiedad_id: data[0].id,
        propietario_id: data[0].usuario_id,
      });
    }
  }

  handleFavorito = async (isFavorito) => {
    const propertyid = this.props.match.params.id;
    const user = auth.getCurrentUser();
    const body = { usuario_id: user.id, propiedad_id: propertyid };
    const result = isFavorito
      ? await delFavorito(propertyid, user.id)
      : await postFavorito(body);
    if (result.status === 200) {
      this.setState({ isFavorito: !isFavorito });
    }
  };

  handleEmail = async () => {
    try {
      Swal.fire({
        title: "¿Quieres contactar al dueño de esta propiedad?",
        icon: "question",
        html:
          "<ul style='text-align:left'><li><p><b>Te podra contactar via correo o teléfono</b></p></li></ul>",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Contactar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          const info = {
            userId: this.props.user.id,
            userEmail: this.props.user.usuario,
            propiedadId: this.props.match.params.id,
            tipo: 0,
          };

          const res = await trackPromise(sendEmail(info));
          if (res.status === 200) {
            this.setState({ contacto: true });
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Información solicitada",
              text: "El vendedor se pondra en contacto contigo",
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "¡Algo salio mal!",
              text:
                "Actualiza la pagina o reporta este problema en info@capitolcity.com",
            });
          }
        }
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Error de conexión", {
          position: "top-left",
          autoClose: 3000,
          pauseOnHover: false,
        });
      }
    }
  };

  reDrawIsochrones = () => {
    const showIso = !this.state.showIso;
    if (showIso) {
      reDrawPoly();
    } else {
      delPoly();
    }
    this.setState({ showIso });
  };

  drawIsochrones = () => {
    const { lat, lng } = this.state.data[0];
    this.setState({ analisisTiempo: true, showIso: true });
    let openrouteservice = require("openrouteservice-js");
    let Isochrones = new openrouteservice.Isochrones({
      api_key: process.env.REACT_APP_ORS,
    });
    Isochrones.calculate({
      locations: [[lng, lat]],
      profile: "driving-car",
      range: [150, 210, 300],
      range_type: "time",
    })
      .then(function (response) {
        let result = response.features;
        let groupCoords = [];
        let coords = [];
        let detailRes = [];
        for (let i = 0; i < result.length; i++) {
          detailRes = result[i].geometry.coordinates[0];
          for (let x = 0; x < detailRes.length; x++) {
            coords.push({ lat: detailRes[x][1], lng: detailRes[x][0] });
          }
          groupCoords.push(coords);
          coords = [];
        }
        drawPoly(groupCoords);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Análisis completo",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (err) {
        var str = "An error occured: " + err;
        console.log(str);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "¡Algo salio mal!",
          text:
            "Actualiza la pagina o reporta este problema en info@capitolcity.com",
        });
      });
  };

  render() {
    if (this.state.data.length) {
      let images = [];
      const { fotos, lat, lng } = this.state.data[0];
      for (let i = 0; i < fotos.length; i++) {
        images.push({
          original:
            "https://res.cloudinary.com/big-duck-tech/image/upload/w_1050,h_650,c_fill/" +
            fotos[i],
          thumbnail:
            "https://res.cloudinary.com/big-duck-tech/image/upload/w_250,h_150,c_fill/" +
            fotos[i],
        });
      }

      const { contacto, analisisTiempo, showIso } = this.state;
      return (
        <React.Fragment>
          <LoadingComponent />
          <IndexNavbar clase="sticky-top" user={this.props.user} />
          <ToastContainer />
          <div className="mx-2">
            <div
              className="row mx-1 py-2 mt-2"
              style={{
                border: "5px solid #f5593d",
                borderRadius: "20px",
                color: "black",
                fontSize: "12px",
              }}
            >
              <PropertyDetailsHeader
                propiedad={this.state.data[0]}
                favorito={this.handleFavorito}
                isFavorito={this.state.isFavorito}
                usuario={this.props.user.id}
              />
            </div>
            {this.props.user.id !== this.state.data[0].usuario_id && (
              <React.Fragment>
                <div className="row mt-2 p-1 justify-content-center">
                  <button
                    type="button"
                    className={
                      "col-md-3 btn btn-lg " +
                      (contacto ? "btn-success" : "btn-info")
                    }
                    onClick={this.handleEmail}
                    disabled={contacto}
                  >
                    <i
                      className={
                        contacto ? "fa fa-check-circle" : "fa fa-envelope"
                      }
                      aria-hidden="true"
                    ></i>{" "}
                    {contacto ? "Solicitado" : "Contactar"}
                  </button>
                  <button
                    type="button"
                    className="ml-2 col-md-3 btn btn-danger btn-lg"
                    onClick={
                      analisisTiempo
                        ? this.reDrawIsochrones
                        : this.drawIsochrones
                    }
                  >
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    {showIso ? "Ocultar Minutos" : "Minutos en Carro"}
                  </button>
                  <button
                    type="button"
                    className="ml-2 col-md-3 btn btn-secondary btn-lg"
                    disabled
                  >
                    <i className="fa fa-star" aria-hidden="true"></i>Análisis
                    Premium
                  </button>
                </div>
              </React.Fragment>
            )}
            <div className="row my-4">
              <div className="col-md-6">
                <ImageGallery
                  showPlayButton={false}
                  showIndex={true}
                  items={images}
                />
                ;
              </div>
              <div className="col-md-6" style={{ height: "500px" }}>
                <OrdinaryMap
                  center={{ lat: lat, lng: lng }}
                  zoom={18}
                  flag={1}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <LoadingComponent />;
    }
  }
}

export default PropertyDetails;

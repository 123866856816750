import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const InboxList = (props) => {
  const {
    propiedadId,
    titulo,
    id,
    nombre,
    apellido,
    usuario,
    telefono,
    registrof,
    respuesta,
    msgTipo,
    estado,
    ciudad,
    colonia,
    calle,
  } = props.data;

  const handleClick = ({ currentTarget: input }) => {
    Swal.fire({
      title: "¿Quieres " + input.name + " a " + nombre + " " + apellido + "?",
      text:
        input.name === "contactar"
          ? "Se enviaran tus datos para que te contacte"
          : "Se enviara una mensaje mostrando que el terreno no se encuentra disponible",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        const body = {
          contactoId: id,
          respuesta: input.name === "contactar" ? 1 : 2,
          propiedadId: propiedadId,
          nombre: nombre,
          apellido: apellido,
          usuario: usuario,
          tipo: 1,
        };
        props.onContact(body, input.name);
      }
    });
  };

  const fecha1 = new Date(registrof);
  const fecha2 = new Date();
  const diffTime = Math.abs(fecha2 - fecha1);
  const diffMin = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return (
    <React.Fragment>
      <div className="list-group-item list-group-item-action flex-column align-items-start">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">
            <b>{nombre + " " + apellido}</b>
          </h5>
          <small>
            <b>
              {" "}
              Hace{" "}
              {diffMin >= 60
                ? diffHours >= 24
                  ? diffDays + (diffDays === 1 ? " día" : " días")
                  : diffHours + (diffHours === 1 ? " hora" : " horas")
                : diffMin + (diffMin === 1 ? " minuto" : " minutos")}
            </b>
          </small>
        </div>
        <p className="mb-1">
          <b>
            {msgTipo === "Enviado"
              ? "Me interesa tu propiedad con titulo: " + titulo + ". "
              : respuesta === 1
              ? "Hola! Me puedes contactar para platicar sobre la propiedad en: "
              : "Una disculpa, la propiedad mostrada ya no se encuentra disponible. "}
            <Link to={`/maps/${propiedadId}`} target="_blank">
              <i className="fa fa-search-plus" aria-hidden="true" />
              <b>Ver Propiedad</b>
            </Link>
          </b>
        </p>
        {msgTipo !== "Enviado" && respuesta === 1 && (
          <React.Fragment>
            <p className="mb-1">
              <b>
                <li>Estado: {estado}</li>
                <li>Ciudad: {ciudad}</li>
                <li>Colonia: {colonia}</li>
                <li>Calle: {calle}</li>
              </b>
            </p>
          </React.Fragment>
        )}
        {((msgTipo !== "Enviado" && respuesta === 1) ||
          msgTipo === "Enviado") && (
          <React.Fragment>
            <p className="mb-1">
              <b>
                <span className="mb-3">Aqui estan mis datos:</span>
                <li>Email: {usuario}</li>
                <li>Telefono: {telefono}</li>
              </b>
            </p>
          </React.Fragment>
        )}
        <div className="row pr-1 mt-2 justify-content-end">
          {respuesta === 0 && (
            <React.Fragment>
              <button
                className="btn btn-round btn-success btn-sm mr-1"
                onClick={handleClick}
                name="contactar"
              >
                Contactar
              </button>
              <button
                className="btn btn-round btn-danger btn-sm"
                onClick={handleClick}
                name="rechazar"
              >
                Rechazar
              </button>
            </React.Fragment>
          )}
          {respuesta === 1 && (
            <React.Fragment>
              <i
                className={
                  "fa fa-check-circle-o fa-lg mt-1 mr-1 " +
                  (msgTipo !== "Enviado" ? "ct-blue" : "ct-green")
                }
                aria-hidden="true"
              />
              <span className={msgTipo !== "Enviado" ? "ct-blue" : "ct-green"}>
                <b>{msgTipo !== "Enviado" ? "Info Vendedor" : "Contactado"}</b>
              </span>
            </React.Fragment>
          )}
          {respuesta === 2 && (
            <React.Fragment>
              <i
                className="fa fa-times-circle-o fa-lg mt-1 mr-1 ct-red"
                aria-hidden="true"
              />
              <span className="ct-red">
                <b>Rechazado</b>
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InboxList;

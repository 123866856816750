import React, { Component } from "react";
import { MapContainer } from "./common/mapContainer";
import IndexNavbar from "./indexNavbar";
import PropertyRow from "./common/propertyRow";
import SearchBar from "./common/searchBar";
import LzPagination from "./common/lzPagination";
import { getEstados } from "../services/namesMexico";
import { paginate } from "../utils/paginate";

class PropertiesMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propiedades: [],
      center: [],
      estados: [],
      currentPage: 1,
      pageSize: 10,
      idEnter: 0,
      showSearch: true,
    };
    this.hanldeUpdate = this.hanldeUpdate.bind(this);
  }

  async componentDidMount() {
    try {
      let center = [{ lat: 25.686045, lng: -100.308479 }];
      if ("geolocation" in navigator) {
        const pos = await this.getCoords();
        console.log(pos);
        center[0].lat = pos.coords.latitude;
        center[0].lng = pos.coords.longitude;
      }
      const { data: estados } = await getEstados();
      this.setState({ center, estados });
    } catch (err) {
      let center = [{ lat: 25.686045, lng: -100.308479 }];
      const { data: estados } = await getEstados();
      this.setState({ center, estados });
    }
  }

  getCoords(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  async hanldeUpdate(propiedades) {
    this.setState({ propiedades });
  }

  handleSearch = () => {
    const showSearch = this.state.showSearch;
    this.setState({ showSearch: !showSearch });
  };

  handleHoverEnter = (idEnter) => {
    this.setState({ idEnter });
  };

  handleHoverExit = () => {
    this.setState({ idEnter: 0 });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  getPagedData = () => {
    const { currentPage, pageSize, propiedades } = this.state;
    const result = paginate(propiedades, currentPage, pageSize);
    return { totalCount: propiedades.length, result };
  };

  render() {
    const { totalCount, result } = this.getPagedData();
    const { pageSize, currentPage, showSearch } = this.state;
    return (
      <React.Fragment>
        <IndexNavbar
          clase="sticky-top"
          user={this.props.user}
          page={this.props.match.path}
        />
        <div className="row mt-2 pl-2">
          <div className="col-md-6">
            <div
              className="sticky-top navbar justify-content-center pt-1 mb-2 pb-2 search-box"
              style={{ top: "83px", overflowY: "auto" }}
            >
              <div className="row" style={{ fontSize: "12px" }}>
                <div className="col-12">
                  <span className="text-danger">
                    <i
                      className={
                        showSearch
                          ? "fa fa-minus-circle fa-lg"
                          : "fa fa-plus-circle fa-lg"
                      }
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={this.handleSearch}
                    />
                    &nbsp;
                    <b>{showSearch ? "Ocultar Filtros" : "Mostrar Filtros"}</b>
                  </span>
                </div>
              </div>
              <div className="row" style={{ fontSize: "12px" }}>
                <span>
                  <i className="fa fa-globe" aria-hidden="true" />
                  <b>Estado</b>
                </span>
                <span className="margin-icon">
                  <i className="fa fa-location-arrow" aria-hidden="true" />
                  <b>Ciudad</b>
                </span>
                <span className="margin-icon">
                  <i className="fa fa-map-marker" aria-hidden="true" />
                  <b>Colonia</b>
                </span>
                <span className="margin-icon">
                  <i className="fa fa-road" aria-hidden="true" />
                  <b>Calle</b>
                </span>
                <span className="margin-icon">
                  <i className="fa fa-file-text" aria-hidden="true" />
                  <b>Servicios</b>
                </span>
                <span className="margin-icon">
                  <i className="fa fa-arrows-h" aria-hidden="true" />
                  <b>Mts²</b>
                </span>
                <span className="margin-icon">
                  <i className="fa fa-money" aria-hidden="true" />
                  <b>Precio</b>
                </span>
              </div>
              {showSearch && (
                <SearchBar
                  estados={this.state.estados}
                  onUpdate={this.hanldeUpdate}
                  user={this.props.user}
                />
              )}
            </div>
            <div className="row justify-content-center container col-mapa">
              {result.length ? (
                result.map((item) =>
                  item.isActive ? (
                    <PropertyRow
                      key={item.id}
                      data={item}
                      onHoverEnter={this.handleHoverEnter}
                      onHoverExit={this.handleHoverExit}
                    />
                  ) : null
                )
              ) : (
                <React.Fragment>
                  <div className="col">
                    <h1 className="ml-4 col-12 text-center">
                      <i className="fa fa-arrow-up fa-1x" aria-hidden="true" />
                      &nbsp;&nbsp;&nbsp;
                      <span>Selecciona un estado</span>
                    </h1>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="row justify-content-center mt-2">
              <LzPagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>

          <div className="col-md-6 propiedades-mapa">
            <div className="row pt-3 justify-content-center legend-mapa">
              <p>
                <i
                  className="fa fa-circle"
                  aria-hidden="true"
                  style={{ color: "rgba(255, 86, 0, 1)", fontWeight: "bolder" }}
                ></i>
                &nbsp;<b>Propiedades Disponibles</b>
              </p>
              <p>
                <i
                  className="fa fa-circle ml-1"
                  aria-hidden="true"
                  style={{
                    color: "rgba(117, 201, 255, 1)",
                    fontWeight: "bolder",
                  }}
                ></i>
                &nbsp;<b>Tus Propiedades</b>
              </p>
            </div>
            <div
              className=""
              style={{
                height: "900px",
              }}
            >
              <MapContainer
                center={this.state.center}
                propiedades={this.state.propiedades}
                hoverId={this.state.idEnter}
                userId={this.props.user ? this.props.user.id : 0}
              ></MapContainer>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PropertiesMap;

import React, { Component } from "react";
import {
  Card,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselCaption,
} from "reactstrap";

class LzCarousel extends Component {
  state = {
    activeIndex: 0,
    animating: false,
    items: [],
  };

  componentDidMount = () => {
    const fotos = this.props.fotos;
    this.setState({ items: fotos });
  };

  onExiting = () => {
    this.setState({ animating: true });
  };
  onExited = () => {
    this.setState({ animating: false });
  };
  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    return (
      <Card className="page-carousel" style={{ marginBottom: "0" }}>
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={this.state.items}
            activeIndex={this.state.activeIndex}
            onClickHandler={this.goToIndex}
          />
          {this.state.items.map((item) => {
            return (
              <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={item}
              >
                <img
                  src={
                    process.env.REACT_APP_CLOUDINARY +
                    "image/upload/w_1440,h_960,c_fill/" +
                    item
                  }
                  alt=""
                />
                <CarouselCaption captionText="" captionHeader="" />
              </CarouselItem>
            );
          })}
          <a
            className="left carousel-control carousel-control-prev"
            data-slide="prev"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              this.previous();
            }}
            role="button"
          >
            <span className="fa fa-angle-left" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control carousel-control-next"
            data-slide="next"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              this.next();
            }}
            role="button"
          >
            <span className="fa fa-angle-right" />
            <span className="sr-only">Next</span>
          </a>
        </Carousel>
      </Card>
    );
  }
}

export default LzCarousel;

import http from "./httpService";

export function newSucursal(body) {
  return http.post("/sucursal", body);
}

export function getSucursales(userId) {
  return http.get("/sucursal/" + userId);
}

export function getSucursalesReg(userId) {
  return http.get("/sucursal/" + userId + "/reg");
}

import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Index from "./components/index";
import Playground from "./components/playground";
import PropertiesMap from "./components/propertiesMap";
import PropertyDetails from "./components/propertyDetails";
import CredentialsEntry from "./components/credentialsEntry";
import PropertyUpdate from "./components/propertyUpdate";
import Premium from "./components/premium";
import Trafico from "./components/trafico";
import ProfileUser from "./components/profileUser";
import PricingModule from "./components/pricingModule";
import Logout from "./components/logout";
import UploadImages from "./components/uploadImages";
import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }
  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/index"
            render={(props) => <Index {...props} user={user} />}
          />
          <Route
            path="/playground"
            render={(props) => <Playground {...props} user={user} />}
          />
          <Route path="/credentials/:flag" component={CredentialsEntry} />
          <Route path="/credentials" component={CredentialsEntry} />
          <ProtectedRoute path="/profile" component={ProfileUser} user={user} />
          <ProtectedRoute
            path="/maps/:id"
            component={PropertyDetails}
            user={user}
          />
          <ProtectedRoute path="/maps" component={PropertiesMap} user={user} />
          <ProtectedRoute
            path="/propiedades/:flag"
            component={PropertyUpdate}
            user={user}
          />
          <ProtectedRoute
            path="/propiedades"
            component={PropertyUpdate}
            user={user}
          />
          <ProtectedRoute path="/premium" component={Premium} user={user} />
          <ProtectedRoute path="/trafico" component={Trafico} user={user} />
          <Route path="/pricing" component={PricingModule} />
          <Route path="/uploadImages" component={UploadImages} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/index" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;

import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import PropagateLoader from "react-spinners/PropagateLoader";

export const LoadingComponent = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(59, 59, 59, 0.50)",
        height: "100%",
        width: "100%",
        zIndex: "2",
        borderRadius: "5px",
        display: promiseInProgress ? "block" : "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        {promiseInProgress === true ? (
          <PropagateLoader
            color="rgba(255, 86, 0, 1)"
            size={30}
            loading={promiseInProgress}
          />
        ) : null}
      </div>
    </div>
  );
};

import React from "react";
import { Line } from "react-chartjs-2";

const LinearChart = (props) => {
  const { dataset, labels, title, min, max, stepSize, legend } = props;
  return (
    <Line
      data={{
        datasets: dataset,
        labels: labels,
      }}
      width={100}
      height={220}
      options={{
        maintainAspectRatio: false,
        legend: { display: legend, position: "bottom" },
        title: { display: true, text: title },
        plugins: { datalabels: { display: false } },
        scales: {
          yAxes: [
            {
              ticks: {
                min: min,
                max: max,
                stepSize: stepSize,
              },
            },
          ],
        },
      }}
    />
  );
};

export default LinearChart;

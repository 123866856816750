import React from "react";
import Joi from "joi-browser";
import _ from "lodash";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { loadStripe } from "@stripe/stripe-js";
import { LoadingComponent } from "./loadingComponent";
import { Modal, Form, Button } from "reactstrap";
import LzForm from "./lzForm";
import PaymentModal from "./paymentModal";
import auth from "../../services/authService";
import { getEstados } from "../../services/namesMexico";
import { OrdinaryMap, getAddress, deleteMarkers } from "./ordinaryMap";
import { checkOutProduct } from "../../services/checkOutService";
import { reqPremium } from "../../services/premiumService";
import { getSucursales } from "../../services/sucursalService";

class PremiumModal extends LzForm {
  state = {
    data: {
      titulo: "",
      estado: "",
      ciudad: "",
      cp: "",
      colonia: "",
      calle: "",
      numeroModal: "",
      lat: 0,
      lng: 0,
      pInteres: "",
      sucursal: 0,
    },
    estados: [],
    ciudades: [],
    marcador: false,
    mapMethod: false,
    payment: false,
    sucMode: false,
    sucursales: [],
    errors: {},
  };

  schema = {
    titulo: Joi.string().required().label("Titulo"),
    estado: Joi.string().required().label("Estado"),
    ciudad: Joi.string().required().label("Ciudad"),
    cp: Joi.number().empty("").default(1).optional().label("Codigo Postal"),
    colonia: Joi.string().empty("").default("cc").optional().label("Colonia"),
    calle: Joi.string().empty("").default("cc").optional().label("Calle"),
    numeroModal: Joi.number()
      .empty("")
      .default("cc")
      .optional()
      .label("Numero"),
    lat: Joi.number().required().min(1).label("Latitud"),
    lng: Joi.number().required().label("Longitud"),
    pInteres: Joi.string()
      .empty("")
      .default("cc")
      .optional()
      .label("Punto de Interes"),
    sucursal: Joi.number().empty("").default(0).optional().label("Sucursal"),
  };

  async componentDidMount() {
    const { data: estados } = await getEstados();
    const user = auth.getCurrentUser();
    //Sucursales crea un error al momento de regresar un usuario Null. Se ajusta para evitar el error.
    let sucursales = null
    if (user) {
      const { data } = await getSucursales(user.id);
      sucursales = data;
    } 
    this.setState({ estados, sucursales });
  }

  mapAddress = async () => {
    const params = _.pickBy(
      _.pick(this.state.data, [
        "estado",
        "ciudad",
        "cp",
        "colonia",
        "calle",
        "numero",
      ]),
      (v) => v
    );
    if (Object.keys(params).length < 5) {
      Swal.fire(
        "Error en Localización",
        "Completa tu dirección para poder localizar tu punto en el mapa.",
        "error"
      );
      return;
    }
    const { estado, ciudad, cp, colonia, calle, numeroModal } = this.state.data;
    const direccion =
      calle +
      " " +
      numeroModal +
      "," +
      colonia +
      "," +
      cp +
      " " +
      ciudad +
      "," +
      estado;
    this.drawPin(direccion);
  };

  mapLocation = () => {
    const params = _.pickBy(
      _.pick(this.state.data, ["estado", "ciudad", "pInteres"]),
      (v) => v
    );
    if (Object.keys(params).length < 3) {
      Swal.fire(
        "Error en Localización",
        "Completa los campos para poder localizar tu punto en el mapa",
        "error"
      );
      return;
    }
    const { estado, ciudad, pInteres } = this.state.data;
    const direccion = pInteres + " " + ciudad + "," + estado;
    this.drawPin(direccion);
  };

  drawPin = async (direccion) => {
    try {
      const { marker, mapas } = await getAddress(direccion);
      const data = { ...this.state.data };
      data["lat"] = marker.getPosition().lat();
      data["lng"] = marker.getPosition().lng();
      this.setState({ data, marcador: true });
      mapas.event.addListener(marker, "dragend", (e) => {
        const data = { ...this.state.data };
        data["lat"] = marker.getPosition().lat();
        data["lng"] = marker.getPosition().lng();
        this.setState({ data });
      });
      Swal.fire(
        "Dirección Localizada",
        "Tu punto esta en el mapa. Puedes arrastrar el pin para localizarlo exactamente donde requieres el análisis.",
        "success"
      );
    } catch (err) {
      Swal.fire(
        "Error en Localización",
        "La ubicación de pudo ser mapeada, envia un correo a info@capitolcity2.com para solicitar apoyo.",
        "error"
      );
    }
  };

  handleDelete = () => {
    deleteMarkers();
    const data = { ...this.state.data };
    data["lat"] = 0;
    data["lng"] = 0;
    this.setState({ data, marcador: false });
  };

  handleToggle = () => {
    const data = {
      titulo: "",
      estado: "",
      ciudad: "",
      cp: "",
      colonia: "",
      calle: "",
      numeroModal: "",
      lat: 0,
      lng: 0,
      pInteres: "",
    };
    const marcador = this.state.marcador;
    if (marcador) {
      deleteMarkers();
    }
    this.setState({ data, marcador: false });
    this.props.onToggle();
  };

  handleMethod = ({ currentTarget }) => {
    const mapMethod =
      currentTarget.getAttribute("name") === "direccion" ? false : true;
    this.setState({ mapMethod });
  };

  handleInput = ({ currentTarget }) => {
    const sucMode =
      currentTarget.getAttribute("name") === "nuevo" ? false : true;
    this.setState({ sucMode });
  };

  selectSuc = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data["sucursal"] = parseInt(input.value);
    this.setState({ data });
  };

  handleCredit = async () => {
    try {
      this.handlePaymentToggle();
      const { lat, lng, titulo, sucursal } = this.state.data;
      const { id } = auth.getCurrentUser();
      const body = {
        usuario_id: id,
        lat: lat,
        lng: lng,
        status: 0,
        titulo: titulo,
        sucursal: sucursal,
      };
      const res = await trackPromise(reqPremium(body));
      if (res.status === 200) {
        const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHED_KEY);
        //insertar proceso en base de datos y regresar el ID
        const body = {
          product: process.env.REACT_APP_STRIPE_PROD_PREM,
          workId: res.data.insertId,
        };
        const result = await trackPromise(checkOutProduct(1, body));
        if (result.status === 200) {
          const stripe = await stripePromise;
          stripe.redirectToCheckout({ sessionId: result.data.id });
        }
      }
    } catch (err) {
      console.log(err);
      Swal.fire(
        "Error",
        "Ocurrio un error, contactanos en info@capitolcity2.com",
        "error"
      );
    }
  };

  handleClean = () => {
    const data = {
      estado: "",
      ciudad: "",
      cp: "",
      colonia: "",
      calle: "",
      numeroModal: "",
      lat: 0,
      lng: 0,
      pInteres: "",
      sucursal: 0,
    };
    this.setState({ data });
    this.handleDelete();
    this.handleToggle();
  };

  handlePaymentToggle = () => {
    this.setState({ payment: false });
  };

  handleSucSubmit = () => {
    const sucursales = this.state.sucursales;
    const data = { ...this.state.data };
    const sucursal = _.filter(sucursales, { id: data["sucursal"] });
    data["titulo"] = sucursal[0]["titulo"].toUpperCase();
    data["lat"] = sucursal[0]["lat"];
    data["lng"] = sucursal[0]["lng"];
    this.setState({ data, payment: true });
  };

  doSubmit = (e) => {
    const data = { ...this.state.data };
    data["titulo"] = data["titulo"].toUpperCase();
    this.setState({ data, payment: true });
  };

  render() {
    const { modal } = this.props;
    const { marcador, mapMethod, payment, sucMode, sucursales } = this.state;
    const { lat, lng, titulo, sucursal } = this.state.data;
    const body = { lat, lng, titulo, sucursal };
    return (
      <React.Fragment>
        <PaymentModal
          modal={payment}
          onToggle={this.handlePaymentToggle}
          onCredit={this.handleCredit}
          onPayment={this.handleClean}
          product={1}
          body={body}
        />
        <Modal isOpen={modal} toggle={this.handleToggle} className="modal-lg">
          <LoadingComponent />
          <div className="modal-header">
            <h3 className="text-center">
              <b>Selecciona el punto para tu análisis</b>
            </h3>
          </div>
          <div className="modal-body px-4 pb-4 pt-2">
            <div className="row justify-content-center">
              <div className="col-md-4 text-center">
                <p
                  className={"subTitlePDng " + (!sucMode ? "selectedPDng" : "")}
                  onClick={this.handleInput}
                  name="nuevo"
                >
                  <b>Punto Nuevo</b>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p
                  className={"subTitlePDng " + (sucMode ? "selectedPDng" : "")}
                  onClick={this.handleInput}
                  name="sucursal"
                >
                  <b>Sucursal</b>
                </p>
              </div>
            </div>
            {sucMode ? (
              <React.Fragment>
                <label>
                  <b>Sucursal</b>
                </label>
                <select
                  className="form-control"
                  onChange={this.selectSuc}
                  value={sucursal}
                >
                  <option value="0"></option>
                  {sucursales.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.titulo}
                    </option>
                  ))}
                </select>
                <div className="row pt-3 justify-content-center">
                  <button
                    className="btn btn-danger col-md-3"
                    onClick={this.handleSucSubmit}
                  >
                    {" "}
                    Solicitar Análisis{" "}
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Form className="p-2" onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="col">
                      {this.renderInput(
                        "titulo",
                        "Título del Análisis",
                        "text",
                        "Ej. Punto Norte Saltillo,Coah"
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-6">
                      {this.renderSelect(
                        "estado",
                        "Estado",
                        this.state.estados,
                        1
                      )}
                    </div>
                    <div className="col-6">
                      {this.renderSelect(
                        "ciudad",
                        "Ciudad",
                        this.state.ciudades
                      )}
                    </div>
                  </div>
                  <div className="form-row py-3 justify-content-center">
                    <p
                      className={
                        "text-center col-4 subTitleP " +
                        (mapMethod ? "" : "selectedP")
                      }
                      onClick={this.handleMethod}
                      name="direccion"
                    >
                      <b>Dirección</b>
                    </p>
                    <p
                      className={
                        "text-center col-4 ml-2 subTitleP " +
                        (!mapMethod ? "" : "selectedP")
                      }
                      onClick={this.handleMethod}
                      name="punto"
                    >
                      <b>Punto de Interés</b>
                    </p>
                  </div>
                  {!mapMethod && (
                    <div className="form-row">
                      <div className="col-3">
                        {this.renderInput("cp", "Código Postal")}
                      </div>
                      <div className="col-3">
                        {this.renderInput("colonia", "Colonia")}
                      </div>
                      <div className="col-5">
                        {this.renderInput("calle", "Calle")}
                      </div>
                      <div className="col-1">
                        {this.renderInput("numeroModal", "Num")}
                      </div>
                    </div>
                  )}
                  {mapMethod && (
                    <div className="form-row">
                      <div className="col">
                        {this.renderInput(
                          "pInteres",
                          "Punto de Interés",
                          "text",
                          "Ej. Plaza Galerías"
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-row justify-content-center">
                    <Button
                      className={
                        "col-3 " + (marcador ? "btn-secondary" : "btn-primary")
                      }
                      disabled={marcador}
                      onClick={mapMethod ? this.mapLocation : this.mapAddress}
                    >
                      Localizar Punto
                    </Button>
                    <Button
                      className={
                        "col-3 ml-2 " +
                        (marcador ? "btn-danger" : "btn-secondary")
                      }
                      disabled={!marcador}
                      onClick={this.handleDelete}
                    >
                      Borrar Pin
                    </Button>
                  </div>
                  <div className="form-row pt-2" style={{ height: "350px" }}>
                    <OrdinaryMap
                      center={{ lat: 25.617847, lng: -100.287275 }}
                      zoom={15}
                      flag={0}
                    />
                  </div>
                  <div className="form-row justify-content-center pt-3">
                    <Button className="col-3 btn-danger" type="submit">
                      Solicitar Análisis
                    </Button>
                  </div>
                </Form>
              </React.Fragment>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PremiumModal;

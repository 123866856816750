import React from "react";
import Joi from "joi-browser";
import { Card, Col, Form, Button, CardBody, NavLink } from "reactstrap";
import { trackPromise } from "react-promise-tracker";
import { LoadingComponent } from "./loadingComponent";
import ForgotPassModal from "./forgotPassModal";
import auth from "../../services/authService";
import LzForm from "./lzForm";

class LoginForm extends LzForm {
  state = {
    data: { usuario: "", password: "" },
    errors: {},
    validated: true,
    modal: false,
  };
  schema = {
    usuario: Joi.string().required().email().label("Usuario"),
    password: Joi.string().required().label("Password"),
  };

  handleModal = () => {
    const modal = !this.state.modal;
    this.setState({ modal });
  };

  doSubmit = async () => {
    //Call the server
    try {
      const { data } = this.state;
      await trackPromise(auth.login(data.usuario, data.password));
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        /*const errors = { ...this.state.errors };
        errors.usuario = ex.response.data;*/
        this.setState({ validated: false });
      }
    }
  };

  render() {
    return (
      <Col md="4">
        <ForgotPassModal modal={this.state.modal} onToggle={this.handleModal} />{" "}
        <Card>
          <LoadingComponent />
          <h3 className="text-center">
            <b>Iniciar Sesión</b>
          </h3>
          <CardBody>
            <Form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "usuario",
                "Usuario",
                "text",
                "",
                0,
                "Correo Invalido"
              )}
              {this.renderInput(
                "password",
                "Password",
                "password",
                "",
                0,
                "Contraseña Invalida"
              )}
              {!this.state.validated && (
                <div className="row justify-content-center">
                  <p className="text-danger" style={{ fontWeight: "bolder" }}>
                    <b>Credenciales Invalidas</b>
                  </p>
                </div>
              )}
              <Button
                block
                className="btn-round mt-2"
                color="info"
                type="submit"
              >
                Entrar
              </Button>
            </Form>
            <Button
              block
              className="btn-round mt-2"
              color="danger"
              onClick={this.props.onProcessChg}
              name="registrar"
            >
              Registrarme
            </Button>
            <NavLink
              href="#"
              onClick={this.handleModal}
              className="text-center mt-1 text-sblue"
            >
              {" "}
              <h6>Olvide mi contraseña</h6>
            </NavLink>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default LoginForm;
